import React from 'react';
import { MA_PRIME_RENOV_ALERT_TEST_ID } from '../../../../tests/utils/tests-ids';
import styled from 'styled-components';
import warningIcon from '../../../../assets/img/others/warning_information.svg';
import { Text, TextBold } from '@renolib/renolib-ui-kit';
import { SizedImg } from './common';

export const MaPrimeRenovAlertDialog = () => (
    <>
        <div className='d-flex justify-content-center mb-1'>
            <SizedImg src={warningIcon} alt='' />
        </div>
        <CenterElementContainer data-testid={MA_PRIME_RENOV_ALERT_TEST_ID}>
            <TextBold>Important :</TextBold>
            <span className='mt-2'>
                <TextBold>{"À propos de MaPrimeRénov' : "}</TextBold>
                <Text.Label>{"Il est impératif d'attendre l'acceptation de votre dossier par l'ANAH avant d'initier tout travail de rénovation."}</Text.Label>
            </span>
            <span className='mt-3'>
                <TextBold>{' - Dans le cas où RENOLIB agit en tant que mandataire administratif ou mixte : '}</TextBold>
                <Text.Label>{"Nos équipes vous transmettront la lettre d'octroi de l'ANAH dès réception."}</Text.Label>
            </span>
            <span className='mt-3'>
                <TextBold>{' - Si RENOLIB est votre mandataire financier, ou si nous ne sommes pas mandatés sur votre projet : '}</TextBold>
                <Text.Label>{"Vous, ou votre client, recevrez directement la lettre d'octroi de l'ANAH."}</Text.Label>
            </span>
        </CenterElementContainer>
    </>
);

export const CenterElementContainer = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;
