import React, { useEffect } from 'react';
import { BrowserRouter, Router, Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { useSelector, useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';

import history from '../utils/history';
import { publicRoutes } from '../routes';
import formActions from '../store/actions/ui/form';
import applicationConfigurationActions from '../store/actions/application-configuration.actions';
import applicationConfigurationHelper from '../utils/application-configuration.helper';

import TypedRoute from './helpers/TypedRoute';
import Maintenance from './pages/Maintenance';

import { capitalize, isNonEmptyObject } from '../utils';
import { useJimo } from './hooks/useJimo';

const Layout = loadable(() => import('./layout/Layout'));

if (process.env.REACT_APP_NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            Sentry.reactRouterV5BrowserTracingIntegration({ history }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        environment: capitalize(process.env.REACT_APP_NODE_ENV),
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /^https:\/\/backend\.prod-sw\.renolib\.fr/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

export default function App() {
    const dispatch = useDispatch();
    const { errorMessage } = useSelector((state) => state.ui.form);
    const { organization } = useSelector((state) => state.organization);
    const { account } = useSelector((state) => state.auth);
    const { applicationConfiguration } = useSelector((state) => state.applicationConfigurationState);

    useEffect(() => {
        const clickListener = document.addEventListener('click', () => {
            if (errorMessage) dispatch(formActions.resetForm());
        });

        return () => document.removeEventListener('click', clickListener);
    }, [errorMessage, dispatch]);

    useEffect(() => {
        dispatch(applicationConfigurationActions.getActiveApplicationConfiguration());
    }, [dispatch]);

    useJimo();

    useEffect(() => {
        if (['dev', 'development', 'test'].includes(process.env.NODE_ENV) || organization?.isDemo) return;
        if (account) {
            mixpanel.init(`${process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN}`, { debug: true, track_pageview: false, persistence: 'localStorage', test: true });
            mixpanel.identify(account._id);
            mixpanel.people.set({
                $name: account.fullName,
                $email: account.email,
            });
        }
    }, [account, organization]);

    useEffect(() => {
        if (process.env.NODE_ENV === 'production' && isNonEmptyObject(organization)) {
            Sentry.setTag('organization.id', String(organization.id));
            Sentry.setTag('organization.name', String(organization.name));
            Sentry.setTag('organization.siren', String(organization.siren));
        }
    }, [organization]);

    return (
        <BrowserRouter>
            <Router history={history}>
                {applicationConfigurationHelper.isApplicationInMaintenanceMode(applicationConfiguration) ? (
                    <Switch>
                        <Route exact path='/maintenance' component={Maintenance} />
                        <Route path='/' render={() => <Redirect to='/maintenance' />} />
                    </Switch>
                ) : (
                    <Switch>
                        {publicRoutes.map((publicRoute, index) => (
                            <TypedRoute type='public' key={index} path={publicRoute.path} component={publicRoute.component} exact={!!publicRoute.exact} />
                        ))}
                        <Route path='/' component={Layout} />
                    </Switch>
                )}
            </Router>
        </BrowserRouter>
    );
}
