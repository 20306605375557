import _ from 'lodash';

import productHelper, { productCategoryOperations } from './product-helper';
import {
    productCategories,
    productCategoriesValues,
    ventilationTypes,
    productVatRates,
    productTypes,
    productSubCategories,
    productSubCategoriesTranslations,
    productSheetMetalColors,
    productSheetMetalColorsTranslations,
    productStatuses,
    productLayerTwoThicknesses,
    productLayerTwoThicknessesTranslations,
    energyEfficiencyUnits,
    productSheetMetalColorsTranslationsAdditonalComments,
    woodHeatingType,
    productFileTypes,
    brandOfProducts,
    operationInstallationTypes,
    heatingPumpUsagesTranslations,
    heatPumpTypesLabel,
    reflectiveIsolantTranslations,
} from './enums';
import {
    productFieldsDescriptor,
    operationPriceFieldsDescriptor,
    insulatorFieldsDescriptor,
    heatingPumpFieldsDescriptor,
    roofFieldsDescriptor,
    windowFieldsDescriptor,
    individualBoilerFieldsDescriptor,
    ventilationFieldsDescriptor,
    thermalInsulatorFieldsDescriptor,
    heatingPumpWithRegulatorDetailsFieldsDescriptor,
    thermodynamicWaterHeaterFieldsDescriptor,
    mainProductCatalogueFieldsDescriptor,
    otherProductFieldsDescriptor,
    productParamsFieldsDescriptor,
    otherProductParamsFieldsDescriptor,
    heatingSolarWaterFieldsDescriptor,
    independentWoodStoveFieldsDescriptor,
    combineSolarSystemFieldsDescriptor,
    individualBiomassFieldsDescriptor,
    waterHeaterFieldsDescriptor,
    heatingPumpFieldsDescriptorForRenovationGlobal,
    publicLightingFieldsDescriptor,
    photoVoltaicPanelFieldsDescriptor,
    thermostaticValveFieldsDescriptor,
    waterSavingSystemsFieldsDescriptor,
    insulatingCoverFieldsDescriptor,
    doorFieldsDescriptor,
    regulationSystemFieldsDescriptor,
} from './entity_fields_descriptors';
import formValidation from './form_validation';
import {
    LABEL_TYPE_VENTILATION,
    LABEL_MARQUE_BOUCHE_AIR,
    LABEL_REFERENCE_BOUCHE_AIR,
    LABEL_PUISSANCE_ELECTRIQUE,
    LABEL_PUISSANCE_THERMIQUE,
    LABEL_COEFFICIANT_DE_TRANSMISSION,
    LABEL_RESISTANCE,
    LABEL_EFFICACITE_ENERGETIQUE,
    LABEL_FACTEUR_SOLAIRE,
    LABEL_TYPE_FONCTIONNEMENT,
    LABEL_MODELE_VENTILATION,
    LABEL_TYPE_DE_CONSTITUTION,
    LABEL_SET_OF_ELEMENTS_CONSTITUTION_TYPE_VALUE,
    LABEL_EPAISSEUR,
    LABEL_SEASONAL_PERFORMANCE_COEFFICIENT,
    LABEL_NOMINAL_POWER,
    PRODUCT_UNDER_VERIFICATION,
    LABEL_NOMINAL_YIELD,
    LABEL_PARTICLE_EMISSION,
    LABEL_CARBON_MONOXIDE_EMISSIONS,
    LABEL_NITROGEN_OXIDE_EMISSIONS,
    LABEL_GREEN_FLAME_LABEL_SEVEN,
    LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS,
    LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
    LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
    LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS,
    LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS,
    LABEL_SEASONAL_PARTICLE_EMISSIONS,
    LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189,
    LABEL_INPUT_EXCHANGER_THERMAL_EFFICIENCY,
    LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY,
    LABEL_HEATING_PUMP_USAGE,
    LABEL_REGULARTOR_CLASS,
    LABEL_REFERENCE_AVIS_TECHNIQUE,
    LABEL_DATE_VALIDITE_AVIS_TECHNIQUE,
    LABEL_CAPTOR_BRAND,
    LABEL_CAPTOR_REFERENCE,
    LABEL_ISOLANT_REFLECTIVE,
} from './product-constants';
import operationSheetNames from './operation-sheet-names';
import quotationHelper from './quotation-helper';
import productFormDataParser from './product-form-data-parser';
import productCatalogueHelper from './product-catalogue-helper';
import { compareAndExtractUpdatesIfTheyExist, isNonEmptyObject, isNullish, isNullishOrEmpty, isValidValue } from '.';
import { MODAL_TITLE_ADD_PRODUCT, MODAL_TITLE_PROVIDE_PRODUCT } from './form_labels';
import { MANDATORY_SHEET_METAL_COLOR, MANDATORY_THERMAL_RESISTANCE, MANDATORY_THICKNESS } from './messages';
import operationFormHelper from './operation-form-helper';
import { LABEL_INTERNAL_UNIT_REFERENCE, LABEL_EXTERNAL_UNIT_REFERENCE } from './form_labels';

function isHeatingPumpFunctioningTypeRequired(operationSheetName) {
    return operationSheetName.includes('BAR_TH_104');
}

function validateInsulatorData(formState) {
    return formValidation.validateForm(formState, insulatorFieldsDescriptor);
}

function validateThermalInsulatorData(formState) {
    return formValidation.validateForm(formState, thermalInsulatorFieldsDescriptor);
}

function filterFieldsDescriptor(fieldDescriptor, data) {
    const allLabels = data.map(({ label }) => label);
    return fieldDescriptor.filter(({ label }) => allLabels.includes(label));
}
function validateHeatingPumpData(formState, options) {
    if (areHeatingPumpRegulatorDetailsRequired(formState)) {
        const { validForm, formErrors } = formValidation.validateForm(formState, heatingPumpWithRegulatorDetailsFieldsDescriptor);
        addSuffixForRegulatorDetailsErrorMessages(formErrors);

        return { validForm, formErrors };
    }

    return formValidation.validateForm(
        formState,
        options?.isRenovationGlobal
            ? [...heatingPumpFieldsDescriptor, ...filterFieldsDescriptor(heatingPumpFieldsDescriptorForRenovationGlobal, options.operationEfficiencies)]
            : heatingPumpFieldsDescriptor
    );
}

function areHeatingPumpRegulatorDetailsRequired({ operationPrices = [] }) {
    const operationsThatRequiredRegulatorDetails = [operationSheetNames.BAR_TH_159, operationSheetNames.BAR_TH_159_CDP];

    return !!operationPrices.find(({ operation }) => operationsThatRequiredRegulatorDetails.includes(operation));
}

function addSuffixForRegulatorDetailsErrorMessages(formErrors) {
    const regulatorDetailsProperties = heatingPumpWithRegulatorDetailsFieldsDescriptor.splice(heatingPumpWithRegulatorDetailsFieldsDescriptor.length - 3).map(({ property }) => property);
    Object.keys(formErrors).forEach((formErrorsKey) => {
        if (regulatorDetailsProperties.includes(formErrorsKey)) {
            formErrors[formErrorsKey] = `${formErrors[formErrorsKey]} pour la ${quotationHelper.formatOperationSheetName(operationSheetNames.BAR_TH_159)}`;
        }
    });
}

function validateRoofData(formState) {
    return formValidation.validateForm(formState, roofFieldsDescriptor);
}

function validateWindowData(formState) {
    return formValidation.validateForm(formState, windowFieldsDescriptor);
}

function validateIndividualBoilerData(formState) {
    return formValidation.validateForm(formState, individualBoilerFieldsDescriptor);
}

function validateThermodynamicWaterHeater(formState) {
    return formValidation.validateForm(formState, thermodynamicWaterHeaterFieldsDescriptor);
}

function validateHeatingSolarWater(formState) {
    return formValidation.validateForm(formState, heatingSolarWaterFieldsDescriptor);
}

function validateCombineSolarySystem(formState) {
    return formValidation.validateForm(formState, combineSolarSystemFieldsDescriptor);
}

function validateWaterHeaterForm(formState) {
    return formValidation.validateForm(formState, waterHeaterFieldsDescriptor);
}

function validatePublicLightingForm(formState) {
    return formValidation.validateForm(formState, publicLightingFieldsDescriptor);
}

function validatePhotoVoltaicPanelForm(formState) {
    return formValidation.validateForm(formState, photoVoltaicPanelFieldsDescriptor);
}

function validateThermostaticValveForm(formState) {
    return formValidation.validateForm(formState, thermostaticValveFieldsDescriptor);
}

function validateWaterSavingSystemsForm(formState) {
    return formValidation.validateForm(formState, waterSavingSystemsFieldsDescriptor);
}

function validateInsulatingCoverForm(formState) {
    return formValidation.validateForm(formState, insulatingCoverFieldsDescriptor);
}

function validateDoorForm(formState) {
    return formValidation.validateForm(formState, doorFieldsDescriptor);
}

function validateRegulationSystemForm(formState) {
    return formValidation.validateForm(formState, regulationSystemFieldsDescriptor);
}

function validateVentilation(formState) {
    const validationStrategy = {
        [productSubCategories.VENTILATION.SIMPLE_FLOW_VENTILATION]: validateSimpleFlowVentilation,
        [productSubCategories.VENTILATION.DOUBLE_FLOW_VENTILATION]: validateDoubleFlowVentilation,
    }[formState.subCategory];

    return validationStrategy ? validationStrategy(formState) : {};
}

function validateSimpleFlowVentilation(formState) {
    formState[LABEL_MODELE_VENTILATION] = formState.name ? formState.name : formState[LABEL_MODELE_VENTILATION];
    const { validForm, formErrors } = formValidation.validateForm(formState, ventilationFieldsDescriptor.simpleFlowVentilation);
    if (formState[LABEL_TYPE_VENTILATION] === ventilationTypes.TYPE_B) {
        let typeBValidForm = true;
        if (!formState[LABEL_MARQUE_BOUCHE_AIR]) {
            formErrors[LABEL_MARQUE_BOUCHE_AIR] = "Marque des bouches d'entrée d'air obligatoire";
            typeBValidForm = false;
        }
        if (!formState[LABEL_REFERENCE_BOUCHE_AIR]) {
            formErrors[LABEL_REFERENCE_BOUCHE_AIR] = "Référence des bouches d'entrée d'air obligatoire";
            typeBValidForm = false;
        }

        return {
            validForm: validForm && typeBValidForm,
            formErrors,
        };
    }

    return { validForm, formErrors };
}

function validateDoubleFlowVentilation(formState) {
    return formValidation.validateForm(formState, ventilationFieldsDescriptor.doubleFlowVentilation);
}

function validateIndependentWoodStove(formState) {
    const { validForm, formErrors } = formValidation.validateForm(formState, independentWoodStoveFieldsDescriptor);

    if (!formState[LABEL_GREEN_FLAME_LABEL_SEVEN]) {
        return validateIndependentWoodStoveByEnergyEfficiencies({ formState, formErrors, validForm });
    }

    if (formState[LABEL_GREEN_FLAME_LABEL_SEVEN]) {
        return validateGreenFlameLabelSeven({ formState, formErrors, validForm });
    }
    return { validForm, formErrors };
}

function validateIndividualBoilerBiomass(formState) {
    const { validForm, formErrors } = formValidation.validateForm(formState, individualBiomassFieldsDescriptor);

    if (formState[LABEL_GREEN_FLAME_LABEL_SEVEN]) {
        return validateGreenFlameLabelSeven({ formState, formErrors, validForm });
    }

    return { validForm, formErrors };
}

function validateIndependentWoodStoveByEnergyEfficiencies({ formState, formErrors, validForm }) {
    let greenFlameLabelSevenValidForm = true;
    [LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS, LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS, LABEL_PARTICLE_EMISSION, LABEL_CARBON_MONOXIDE_EMISSIONS, LABEL_NITROGEN_OXIDE_EMISSIONS].forEach((label) => {
        if (!formState[label]) {
            formErrors[label] = `${label} obligatoire`;
            greenFlameLabelSevenValidForm = false;
        }
    });

    return {
        validForm: validForm && greenFlameLabelSevenValidForm,
        formErrors,
    };
}

function validateGreenFlameLabelSeven({ formState, formErrors, validForm }) {
    const productFileUrl = findProductFileByType({ formState, productFileType: productFileTypes.GREEN_FLAME_LABEL_SEVEN });
    let greenFlameLabelSevenValidForm = true;
    const file = formState.file || productFileUrl;
    if (!file) {
        formErrors[LABEL_GREEN_FLAME_LABEL_SEVEN] = 'Le fichier à joindre est obligatoire';
        greenFlameLabelSevenValidForm = false;
    }

    return {
        validForm: validForm && greenFlameLabelSevenValidForm,
        formErrors,
    };
}

function validateCategoryData(formState, options = {}) {
    const { category, ...otherData } = formState;
    if (!category) {
        return { validForm: false, formErrors: {} };
    }

    const validationStrategy = {
        [productCategoriesValues.ISOLANT]: validateInsulatorData,
        [productCategoriesValues.POMPE_CHALEUR]: validateHeatingPumpData,
        [productCategoriesValues.TOITURE]: validateRoofData,
        [productCategoriesValues.FENETRE]: validateWindowData,
        [productCategoriesValues.CHAUDIERE_INDIVIDUELLE]: validateIndividualBoilerData,
        [productCategoriesValues.VENTILATION]: validateVentilation,
        [productCategoriesValues.CALORIFUGEAGE]: validateThermalInsulatorData,
        [productCategoriesValues.CHAUFFE_EAU_THERMODYNAMIQUE]: validateThermodynamicWaterHeater,
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL]: validateHeatingSolarWater,
        [productCategoriesValues.SYSTEME_SOLAIRE_COMBINE]: validateCombineSolarySystem,
        [productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS]: validateIndependentWoodStove,
        [productCategoriesValues.CHAUDIERE_BIOMASSE_INDIVIDUELLE]: validateIndividualBoilerBiomass,
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE]: validateWaterHeaterForm,
        [productCategoriesValues.ECLAIRAGE_PUBLIC]: validatePublicLightingForm,
        [productCategoriesValues.PHOTOVOLTAIC_PANELS]: validatePhotoVoltaicPanelForm,
        [productCategoriesValues.THERMOSTATIC_VALVE]: validateThermostaticValveForm,
        [productCategoriesValues.WATER_SAVING_SYSTEMS]: validateWaterSavingSystemsForm,
        [productCategoriesValues.HOUSSE_ISOLANTE]: validateInsulatingCoverForm,
        [productCategoriesValues.PORTE]: validateDoorForm,
        [productCategoriesValues.REGULATION_SYSTEM]: validateRegulationSystemForm,
    }[category];

    return validationStrategy ? validationStrategy(otherData, options) : { validForm: true, formErrors: {} };
}

function validateOperationPrices({ operationPrices = [] }) {
    const operationPricesErrors = {};
    let validOperationPrices = true;
    operationPrices.forEach(({ uuid, ...otherProperties }) => {
        const { validForm, formErrors } = formValidation.validateForm(otherProperties, operationPriceFieldsDescriptor);
        operationPricesErrors[uuid] = validForm ? {} : formErrors;
        validOperationPrices = validOperationPrices && validForm;
    });

    return { validOperationPrices, operationPricesErrors };
}

function isProductFromQuotation(operationSheetName) {
    return !!operationSheetName;
}

function validateProductForm(formState = {}, operationSheetName, { isRenovationGlobal = false, operationEfficiencies = [] } = { isRenovationGlobal: false, operationEfficiencies: [] }) {
    const additionalData = { category: formState.category, operationSheetName };
    const defaultFieldsValidation = isProductFromQuotation(operationSheetName)
        ? formValidation.validateForm(formState, productFieldsDescriptor, additionalData)
        : formValidation.validateForm(formState, mainProductCatalogueFieldsDescriptor, additionalData);

    if (!operationSheetName && (!formState.type || !formState.category)) {
        return { validForm: false, formErrors: defaultFieldsValidation.formErrors };
    }

    const operationPricesValidation = validateOperationPrices(formState);
    const categoryDataValidation = validateCategoryData(formState, { isRenovationGlobal, operationEfficiencies });
    const batOrBarEn109ProductDetailsValidation = validateBatOrBarEn109ProductDetails(formState, operationSheetName);

    const validForm = defaultFieldsValidation.validForm && operationPricesValidation.validOperationPrices && categoryDataValidation.validForm && batOrBarEn109ProductDetailsValidation.validForm;

    const formErrors = {
        ...defaultFieldsValidation.formErrors,
        ...operationPricesValidation.operationPricesErrors,
        ...categoryDataValidation.formErrors,
        ...batOrBarEn109ProductDetailsValidation.formErrors,
    };

    return { validForm, formErrors };
}

function validateBatOrBarEn109ProductDetails(formState, operationSheetName) {
    let validForm = true;
    const formErrors = {};
    if ([operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName) && formState[LABEL_TYPE_DE_CONSTITUTION] === LABEL_SET_OF_ELEMENTS_CONSTITUTION_TYPE_VALUE) {
        const { productDetails = {} } = formState;
        if (!productDetails.layerOne?.sheetMetalColor) {
            validForm = false;
            formErrors.layerOneSheetMetalColor = MANDATORY_SHEET_METAL_COLOR;
        }
        if (!productDetails.layerTwo?.thickness && !productDetails.layerFour?.thermalResistance) {
            validForm = false;
            formErrors.layerTwoThickness = MANDATORY_THICKNESS;
        }
        if (!productDetails.layerThree?.thermalResistance) {
            validForm = false;
            formErrors.layerThreeThermalResistance = MANDATORY_THERMAL_RESISTANCE;
        }
    }

    return { validForm, formErrors };
}

function validateOtherProductForm(formState = {}) {
    const defaultFieldsValidation = formValidation.validateForm(formState, otherProductFieldsDescriptor);

    if (!formState.type) {
        return { validForm: false, formErrors: defaultFieldsValidation.formErrors };
    }

    return { validForm: defaultFieldsValidation.validForm, formErrors: defaultFieldsValidation.formErrors };
}

function validatePartialFormProduct(formState) {
    const defaultFieldsValidation = formValidation.validateForm(formState, mainProductCatalogueFieldsDescriptor, formState);
    const categoryDataValidation = validateCategoryData(formState);

    const validForm = defaultFieldsValidation.validForm && categoryDataValidation.validForm;
    const formErrors = { ...defaultFieldsValidation.formErrors, ...categoryDataValidation.formErrors };

    return { validForm, formErrors };
}

function getCategoryDefaultValue(operationSheetName) {
    return productCategories[productHelper.getProductCategory(operationSheetName)];
}

function getSelectOperationOptions({ category, subCategory, operationPrices = [], operationSheets = [], existingOperations = [] }) {
    let operations = productCategoryOperations[category];
    if (isNullish(operations)) return [];

    if (existingOperations.length > 0) operations = operations.filter((operationSheetName) => !isOperationAlreadySelected(operationSheetName, existingOperations));
    return operations
        .filter((operationSheetName) => !isOperationAlreadySelected(operationSheetName, operationPrices))
        .filter((operationSheetName) => {
            const { subCategories = [] } = extractOperationSheet(operationSheetName, operationSheets);

            return subCategory ? subCategories.includes(subCategory) : true;
        })
        .map((operationSheetName) => {
            const operationSheet = extractOperationSheet(operationSheetName, operationSheets);
            return {
                label: operationSheet.formattedOperationSheetName,
                value: operationSheet,
            };
        })
        .filter(({ label }) => isValidValue(label));
}

function extractOperationSheet(operationSheetName, operationSheets = []) {
    return operationSheets.find(({ name }) => name === operationSheetName) || {};
}

function isOperationAlreadySelected(operation, operationPrices) {
    return !!operationPrices.find((operationPrice) => operationPrice.operationSheetName === operation);
}

function getSelectRegulatorClassOptions() {
    return [
        { label: 'IV', value: 4 },
        { label: 'V', value: 5 },
        { label: 'VI', value: 6 },
        { label: 'VII', value: 7 },
        { label: 'VIII', value: 8 },
    ];
}

function getSelectRegulatorClassPlaceholder(regulatorClass) {
    if (regulatorClass) {
        const { label } = getSelectRegulatorClassOptions().find((regulatorClassOptions) => regulatorClassOptions.value === regulatorClass);
        return label;
    }
}

function getEnergyEfficiencyUnit(label) {
    const energyEfficiencyUnits = {
        [LABEL_EFFICACITE_ENERGETIQUE]: '%',
        [LABEL_COEFFICIANT_DE_TRANSMISSION]: 'W/m².K',
        [LABEL_RESISTANCE]: 'm².K/W',
        [LABEL_PUISSANCE_ELECTRIQUE]: 'WThC/(m³/h)',
        [LABEL_PUISSANCE_THERMIQUE]: 'kW',
        [LABEL_FACTEUR_SOLAIRE]: 'W/m².K',
        [LABEL_SEASONAL_PERFORMANCE_COEFFICIENT]: '',
        [LABEL_NOMINAL_POWER]: 'kW',
        [LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS]: '%',
        [LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS]: 'mgC/Nm3',
        [LABEL_NOMINAL_YIELD]: '%',
        [LABEL_PARTICLE_EMISSION]: 'mg/Nm3',
        [LABEL_CARBON_MONOXIDE_EMISSIONS]: 'mg/Nm3',
        [LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189]: '%',
        [LABEL_NITROGEN_OXIDE_EMISSIONS]: 'mg/Nm3',
        [LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS]: 'mg/Nm3',
        [LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS]: 'mg/Nm3',
        [LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS]: 'mg/Nm3',
        [LABEL_SEASONAL_PARTICLE_EMISSIONS]: 'mg/Nm3',
        [LABEL_INPUT_EXCHANGER_THERMAL_EFFICIENCY]: '%',
        [LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY]: '%',
    };

    return energyEfficiencyUnits[label] || '';
}

function getEnergyEfficiencyFieldLabel(label, value) {
    return `${value} ${getEnergyEfficiencyUnit(label)}`;
}

function isProductQuerySearchable(productQuery = '') {
    return productQuery.trim().replace(/\s/g, '').length % 2 === 0;
}

function getAutocompleteProductsOptions(products = []) {
    return products.map((product) => ({
        label: product.title,
        value: product.uuid,
    }));
}

function getSelectVatRateOptions() {
    return Object.values(productVatRates).map((value) => ({
        label: `${value * 100} %`,
        value,
    }));
}

function getSelectVatRatePlaceholder({ vatRate }) {
    return vatRate || vatRate === 0 ? `${vatRate * 100} %` : '';
}

function validateProductParams(product, errorMessages, paramsFieldDescriptor) {
    const { validForm, formErrors } = formValidation.validateForm(product, paramsFieldDescriptor);
    Object.values(formErrors).forEach((errorMessage) => errorMessages.push(errorMessage));

    return validForm;
}

function validateMainProduct(product) {
    let valid = true;
    const errorMessages = [];

    if (!isMainProductSelected(product)) {
        valid = false;
        errorMessages.push('Veuillez sélectionner un produit');
    }
    const validProductParams = validateProductParams(product, errorMessages, productParamsFieldsDescriptor);
    const validVariations = validateProductVariations(product);

    return {
        valid: valid && validProductParams && validVariations,
        errorMessages,
    };
}

function isMainProductSelected(product) {
    return isNonEmptyObject(product.product) && Object.keys(product.product).length > 1;
}

function validateProductVariations({ declinations = [] }) {
    return declinations.reduce((accumulator, { length, thickness, diameter }) => {
        return accumulator && isValidProductVariationValue(length) && isValidProductVariationValue(thickness) && isValidProductVariationValue(diameter);
    }, true);
}

function isValidProductVariationValue(value = 0) {
    return value > 0;
}

function validateOtherProduct(product) {
    let valid = true;
    const errorMessages = [];

    if (!product.product.title) {
        valid = false;
        errorMessages.push('Veuillez sélectionner un produit');
    }
    const validProductParams = validateProductParams(product, errorMessages, otherProductParamsFieldsDescriptor);

    return {
        valid: valid && validProductParams,
        errorMessages,
    };
}

function isOtherProductAlreadySelected(providedProduct, selectedOtherProducts) {
    return !!selectedOtherProducts.find(({ product }) => product.title === providedProduct.title);
}

function getAutocompleteOtherProductOptions(fetchedProducts = [], selectedOtherProducts = []) {
    return fetchedProducts
        .filter((product) => !isOtherProductAlreadySelected(product, selectedOtherProducts))
        .map((product) => ({
            label: product.title,
            value: product.uuid,
        }));
}

function isUnitInputDisabled(product, editable) {
    const productType = _.get(product, 'product.type');

    return productType === productTypes.MAIN_PRODUCT || !editable;
}

function canShowGenericProductForm(operationSheetName, formState) {
    return operationSheetName || (formState.type === productTypes.MAIN_PRODUCT && formState.category);
}

const getExclusionDetailsToSpecifyValueByLabel = (product, label) => {
    if (!product || !product.detailsPerOperation) return;
    const detailsPerOperation = Object.values(product.detailsPerOperation)[0];
    if (productHelper.productExclusionDetailsToSpecifyExist(detailsPerOperation)) {
        const productExclusionDetailsToSpecify = detailsPerOperation.details.productExclusionDetailsToSpecify.find(
            (productExclusionDetailsToSpecify) => productExclusionDetailsToSpecify.label === label
        );
        if (productExclusionDetailsToSpecify) return productExclusionDetailsToSpecify.value;
    }
};

const getDetailsProductByLabel = (product = {}, label, operation) => {
    if (product?.detailsPerOperation) {
        const detailsPerOperation = operation ? product.detailsPerOperation[operation] : Object.values(product.detailsPerOperation)[0];
        if (detailsPerOperation && detailsPerOperation.details && detailsPerOperation.details.productDetailsToSpecify && detailsPerOperation.details.productDetailsToSpecify.length > 0) {
            const productDetailsToSpecify = detailsPerOperation.details.productDetailsToSpecify.find((productDetailsToSpecify) => productDetailsToSpecify.label === label);
            if (productDetailsToSpecify) return productDetailsToSpecify.value;
        }
    }

    if (product?.energyEfficiencyProperties) {
        const energyEfficiency = product.energyEfficiencyProperties.find((energyEfficiency) => energyEfficiency.label === label);
        return energyEfficiency?.value;
    }
};

function getProductSpecifiedDetail(product, { label, returnValue = true } = {}) {
    if (isNullishOrEmpty(product.detailsPerOperation)) return '';

    const { details = {} } = Object.values(product.detailsPerOperation)[0];
    const { productDetailsToSpecify = [], productExclusionDetailsToSpecify = [] } = details;
    const specifiedDetail = [...productDetailsToSpecify, productExclusionDetailsToSpecify].find((detail) => detail.label === label);

    return isNonEmptyObject(specifiedDetail) ? (returnValue ? specifiedDetail.value : specifiedDetail) : '';
}

function formatFoundUpdates(product, potentialUpdatedProduct) {
    const parsedFormData = productFormDataParser.parseProductFormData(potentialUpdatedProduct);
    parsedFormData.energyEfficiencyProperties = productCatalogueHelper.assignIdToEnergyEfficencyProperties(product.energyEfficiencyProperties, parsedFormData.energyEfficiencyProperties);
    let foundUpdates = compareAndExtractUpdatesIfTheyExist(product, parsedFormData);
    foundUpdates = productCatalogueHelper.checkIfObjectsAreDeeplyEqualAndRemoveThemFromFoundUpdatesIfTheyAre(product, parsedFormData, foundUpdates);

    return foundUpdates;
}

function getInstallationTypeForSpecificsOperations(quotationProduct, operationSheetName, installationTypeParam) {
    let installationType = installationTypeParam;

    if ([operationSheetNames.BAR_TH_104, operationSheetNames.BAR_TH_104_CDP].includes(operationSheetName)) {
        const productDetailsToSpecify = _.get(quotationProduct, `product.detailsPerOperation.${operationSheetName}.details.productDetailsToSpecify`);
        if (productDetailsToSpecify) {
            const { value } = productDetailsToSpecify.find((detail) => detail.label === LABEL_TYPE_FONCTIONNEMENT);
            installationType = value;
        }
    } else if (
        installationType &&
        [operationSheetNames.BAR_TH_127V_A36_3, operationSheetNames.BAR_TH_127V_A40_4, operationSheetNames.BAR_TH_127V_A54_5].includes(operationSheetName) &&
        installationType !== operationInstallationTypes.INDIVIDUAL_INSTALLATION
    ) {
        installationType = installationType.concat(`_${quotationProduct.product.name}`);
    }
    return installationType;
}

function canCheckProductCompliancy(quotationProduct, operationSheetName, installationType) {
    return isValidValue(getProductCompliancy(quotationProduct, operationSheetName, installationType));
}

function getProductCompliancy(quotationProduct, operationSheetName, installationTypeParam) {
    let installationType = getInstallationTypeForSpecificsOperations(quotationProduct, operationSheetName, installationTypeParam);
    const { product } = quotationProduct;
    if (productHelper.isPhotovoltaicPanelsCategory(product)) return isProductWithValidStatus(product);
    if (quotationProduct.blockValidity.valid && isDoubleWindowInstallationType(installationType) && !productContainsDoubleWindowInstallationType({ product, operationSheetName })) return false;

    return _.get(quotationProduct, `product.detailsPerOperation.${operationSheetName}.compliancyByInstallationTypes.${installationType}.compliant`);
}

function getProductTooltipMessage(quotationProduct, operationSheetName, installationType) {
    const compliancyMessages = getProductCompliancyMessages(quotationProduct, operationSheetName, installationType);
    const compliant = getProductCompliancy(quotationProduct, operationSheetName, installationType);

    const { product } = quotationProduct;
    if (quotationProduct?.blockValidity?.valid && !isProductWithValidStatus(product)) return { PENDING: PRODUCT_UNDER_VERIFICATION, REJECTED: 'Produit rejeté' }[quotationProduct.product.status];
    if (quotationProduct.blockValidity.valid && isDoubleWindowInstallationType(installationType) && !productContainsDoubleWindowInstallationType({ product, operationSheetName }))
        return "Ce produit n'est pas éligible pour le type d'installation double(s) fenêtre(s)";

    return !compliant && compliancyMessages.length > 0 ? compliancyMessages[0] : '';
}

function productContainsDoubleWindowInstallationType({ product, operationSheetName }) {
    return (
        operationSheetName === operationSheetNames.BAR_EN_104V_A54_2 &&
        !!_.get(product, `product.detailsPerOperation.${operationSheetName}.compliancyByInstallationTypes.${operationInstallationTypes.DOUBLES_FENETRES}`)
    );
}

function isDoubleWindowInstallationType(installationType) {
    return installationType === operationInstallationTypes.DOUBLES_FENETRES;
}

function getProductCompliancyMessages(quotationProduct, operationSheetName, installationTypeParam) {
    let installationType = getInstallationTypeForSpecificsOperations(quotationProduct, operationSheetName, installationTypeParam);
    return _.get(quotationProduct, `product.detailsPerOperation.${operationSheetName}.compliancyByInstallationTypes.${installationType}.compliancyMessages`, []);
}

function canShowProductDetailsTitle(formState) {
    if (isProductThatCanShowProductFinder(formState)) return false;

    return formState.category && !isCalorifugeProduct(formState.category);
}

function isCalorifugeProduct(category) {
    return category === productCategoriesValues.CALORIFUGEAGE;
}

function canShowCertificationInput(formState = {}) {
    return formState.category === productCategoriesValues.ISOLANT;
}

function getProductVariationFormLabels(index) {
    return {
        thicknessLabel: index === 0 ? 'Epaisseur (mm)' : '',
        diameterLabel: index === 0 ? 'Diamètre (mm)' : '',
        lengthLabel: index === 0 ? 'Longueur (m)' : '',
    };
}

function isProductQuantityInputDisabled({ editable, product, operationSheetName, isOperationOfRenovationGlobal = false, operation, operations } = {}) {
    return (
        !editable ||
        canShowProductVariations(product) ||
        isOperationUnitValueEqualToMainProductQuantity(operationSheetName) ||
        (!isOperationOfRenovationGlobal && isProductQuantityEqualToOne(operationSheetName))
    );
}

function canShowProductVariations(quotationProduct = {}) {
    const { product = {} } = quotationProduct;

    return isCalorifugeProduct(product.category);
}

function isOperationUnitValueEqualToMainProductQuantity(operationSheetName) {
    return [operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName);
}

function isProductQuantityEqualToOne(operationSheetName) {
    return operationSheetName === operationSheetNames.BAR_TH_129V_A27_3;
}

function extractEnergyEfficiencyValue(energyEfficiencyProperties, { label } = {}) {
    return energyEfficiencyProperties.find((property) => property.label === label)?.value;
}

function extractSpecifiedDetailValue(detailsPerOperation, { label, operationSheetName } = {}) {
    if (isNullishOrEmpty(detailsPerOperation)) return;

    const operationDetails = extractOperationDetails(detailsPerOperation, { operationSheetName });
    const { productDetailsToSpecify = [] } = operationDetails?.details;

    return productDetailsToSpecify.find((specifiedDetail) => specifiedDetail.label === label)?.value;
}

function extractOperationDetails(detailsPerOperation, { operationSheetName } = {}) {
    let operationDetails = detailsPerOperation[operationSheetName];
    if (isNullish(operationDetails)) {
        operationDetails = detailsPerOperation[Object.keys(detailsPerOperation)[0]];
    }

    return operationDetails;
}

function canShowInstallationDescriptionInput({ operationSheetName }, formState) {
    if (isProductThatCanShowProductFinder(formState) && !formState.product) return false;

    return ![operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName);
}

function canShowNameInput({ operationSheetName }) {
    return ![operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName);
}

function isMainProductSearchable({ operationSheetName }) {
    return ![operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName);
}

function getCreateProductModalTitle({ operationSheetName }) {
    return isMainProductSearchable({ operationSheetName }) ? MODAL_TITLE_ADD_PRODUCT : MODAL_TITLE_PROVIDE_PRODUCT;
}

function canShowProductCreationSuccessDialog({ productType, operationSheetName }) {
    return (
        [productTypes.OTHER_PRODUCT, productTypes.INDUCED_WORK_PRODUCT].includes(productType) ||
        (productType === productTypes.MAIN_PRODUCT && ![operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName))
    );
}

function isBrandInputDisabled({ operationSheetName, formState, isUpdatable }) {
    return !isUpdatable || isSetOfElementsConstitutionType(operationSheetName, formState);
}

function isReferenceInputDisabled({ operationSheetName, formState, isUpdatable }) {
    return !isUpdatable || isSetOfElementsConstitutionType(operationSheetName, formState);
}

function isSetOfElementsConstitutionType(operationSheetName, formState) {
    return [operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName) && formState[LABEL_TYPE_DE_CONSTITUTION] === LABEL_SET_OF_ELEMENTS_CONSTITUTION_TYPE_VALUE;
}

function canShowSubCategorySelect({ type, category }) {
    return type === productTypes.MAIN_PRODUCT && doesCategoryHaveSubCategories(category);
}

function doesCategoryHaveSubCategories(category) {
    return Object.keys(productSubCategories).includes(category);
}

function getSelectSubCategoryOptions({ category }, operationSheetName) {
    const subCategories = productSubCategories[category];
    return isNonEmptyObject(subCategories)
        ? filterSubCategoriesByOperation(Object.values(subCategories), operationSheetName).map((subCategory) => ({
              label: productSubCategoriesTranslations[subCategory],
              value: subCategory,
          }))
        : [];
}

function filterSubCategoriesByOperation(subCategories, operationSheetName) {
    return operationSheetName ? getProductSubCategoriesForOperation(operationSheetName) : subCategories;
}

function getProductSubCategoriesForOperation(operationSheetName) {
    return (
        {
            BAR_TH_104: [productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU, productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU],
            BAR_TH_159: [productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE],
            BAR_TH_129: [productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR],
            BAR_TH_112: [
                productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.POELE_GRANULES,
                productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.POELE_BUCHES,
                productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE,
                productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE,
            ],
            BAR_TH_125: [productSubCategories.VENTILATION.DOUBLE_FLOW_VENTILATION],
            BAR_TH_127: [productSubCategories.VENTILATION.SIMPLE_FLOW_VENTILATION],
            BAR_TH_171: [productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU],
            BAR_TH_172: [productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU, productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU],
            BAT_EQ_133: [productSubCategories.WATER_SAVING_SYSTEMS.SHOWER_HEAD, productSubCategories.WATER_SAVING_SYSTEMS.JET_REGULATOR],
        }[extractOperationShortName(operationSheetName)] || []
    );
}

function extractOperationShortName(operationSheetName) {
    if (isNullishOrEmpty(operationSheetName)) return operationSheetName;

    return operationSheetName.split('V_')[0];
}

function canShowHeatingPumpFunctionningTypeCheckbox({ subCategory }) {
    return subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE;
}

function canShowRegulatorForm({ subCategory }) {
    return subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE;
}

function canShowOperationsPriceForm(formState) {
    const { category, subCategory } = formState;

    if (isNullish(category)) return false;
    if (isProductThatCanShowProductFinder(formState) && !formState.product) return false;

    const categoryCheckingStrategy = {
        [productCategoriesValues.POMPE_CHALEUR]: () => isValidValue(subCategory),
        [productCategoriesValues.VENTILATION]: () => isValidValue(subCategory),
    }[category];

    return categoryCheckingStrategy ? categoryCheckingStrategy() : true;
}

function getSelectSheetMetalColorOptions() {
    return Object.values(productSheetMetalColors).map((color) => ({
        value: color,
        label: translateProductSheetMetalColor(color),
    }));
}

function translateProductSheetMetalColor(color) {
    return `${productSheetMetalColorsTranslations[color]} (${productSheetMetalColorsTranslationsAdditonalComments[color]})`;
}

function getSelectLayerTwoThicknessOptions() {
    return Object.values(productLayerTwoThicknesses).map((color) => ({
        value: color,
        label: productLayerTwoThicknessesTranslations[color],
    }));
}

function canShowProductDetailsForBarEn109OrBatEn109(productDetails, operationSheetName) {
    return (
        [operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName) &&
        isNonEmptyObject(productDetails) &&
        isNonEmptyObject(productDetails.layerOne) &&
        isNonEmptyObject(productDetails.layerThree)
    );
}

function canInitializeProductDetailsForBarEn109OrBatEn109({ operationSheetName, operations }) {
    if (![operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName)) return false;

    const relatedOperationSheetName = operationSheetName === operationSheetNames.BAT_EN_109 ? operationSheetNames.BAT_EN_106 : operationSheetNames.BAR_EN_106;
    return operationFormHelper.findOperation(operations, relatedOperationSheetName)?.compliant === true;
}

function extractProductDetailsForBarEn109OrBatEn109FromRelatedOperation(operations, operationSheetName) {
    const relatedOperationSheetName = operationSheetName === operationSheetNames.BAT_EN_109 ? operationSheetNames.BAT_EN_106 : operationSheetNames.BAR_EN_106;
    const operation = operationFormHelper.findOperation(operations, relatedOperationSheetName);
    const product = extractMainProduct(operation?.products);
    if (isNonEmptyObject(product)) {
        const thickness = extractSpecifiedDetailValue(product.detailsPerOperation, { operationSheetName: relatedOperationSheetName, label: LABEL_EPAISSEUR });
        const thermalResistance = extractEnergyEfficiencyValue(product.energyEfficiencyProperties, { label: LABEL_RESISTANCE });

        return { layerThree: { thickness, thermalResistance } };
    }

    return {};
}

function extractMainProduct(quotationProducts = []) {
    return quotationProducts.find(({ product }) => product?.type === productTypes.MAIN_PRODUCT)?.product;
}

function isMainProductQuantityInputDisabled(product, operation, operations) {
    return product.product.type === productTypes.MAIN_PRODUCT ? canShowProductVariations(product) || operationFormHelper.getOperationEn109WithEn106Included(operation, operations) : false;
}

function isProductWithValidStatus(product) {
    return isValidValue(product?.status) && [productStatuses.CONFIRMED, productStatuses.UNVERIFIED, productStatuses.WITHOUT_VERIFICATION].includes(product.status);
}

function translateLayerTwoThickness(layerTwoThickness) {
    return productLayerTwoThicknessesTranslations[layerTwoThickness] || `${layerTwoThickness} ${energyEfficiencyUnits.THICKNESS}`;
}

function canShowProductDetailsLayerTwo(productDetails) {
    return isNullish(productDetails.layerFour?.thermalResistance) || Number(productDetails.layerFour?.thermalResistance) === 0;
}

function canShowSolarFactorCalculationNote(operationSheetName, { product } = {}) {
    if (![operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName) || isNullishOrEmpty(product)) return false;

    const roofConstitutionType =
        extractSpecifiedDetailValue(product.detailsPerOperation, { operationSheetName: operationSheetNames.BAT_EN_109, label: LABEL_TYPE_DE_CONSTITUTION }) ||
        extractSpecifiedDetailValue(product.detailsPerOperation, { operationSheetName: operationSheetNames.BAR_EN_109, label: LABEL_TYPE_DE_CONSTITUTION });

    return roofConstitutionType === LABEL_SET_OF_ELEMENTS_CONSTITUTION_TYPE_VALUE && isValidValue(product?.solarFactorCalculationNote?.fileDownloadUrl);
}

function getFunctioningTypeBySubCategory(subCategory) {
    if (subCategory === productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.POELE_GRANULES) {
        return woodHeatingType.COMBUSTIBLE_SOUS_FORME_GRANULES;
    } else if (subCategory === productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.POELE_BUCHES) {
        return woodHeatingType.COMBUSTIBLE_SOUS_AUTRE_FORME_GRANULES;
    }
    return '';
}

function findProductFileByType({ formState, productFileType }) {
    return (
        formState?.productFiles?.find((productFile) => productFile.productFileType === productFileType) ||
        (formState?.product && formState?.product?.productFiles?.find((productFile) => productFile.productFileType === productFileType))
    );
}

function typeOfCategoriesThatCanSearchProduct(formState) {
    return [
        productCategoriesValues.CHAUFFE_EAU_THERMODYNAMIQUE,
        productCategoriesValues.ISOLANT,
        productCategoriesValues.CALORIFUGEAGE,
        productCategoriesValues.SYSTEME_SOLAIRE_COMBINE,
        productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL,
        productCategoriesValues.CHAUDIERE_INDIVIDUELLE,
        productCategoriesValues.CHAUFFE_EAU_SOLAIRE,
        productCategoriesValues.REGULATION_SYSTEM,
    ].includes(formState?.category);
}

function typeOfCategoriesWithSubCategoriesThatCanSearchProduct(formState) {
    return [productCategoriesValues.POMPE_CHALEUR, productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS, productCategoriesValues.VENTILATION].includes(formState?.category);
}

function typeOfSubCategoriesThatCanSearchProduct(formState) {
    return (
        formState?.subCategory &&
        [
            productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
            productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
            productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU,
            productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE,
            productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR,
            productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.POELE_GRANULES,
            productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.POELE_BUCHES,
            productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.CUISINIERE_UTILISEE_COMME_MODE_DE_CHAUFFAGE,
            productSubCategories.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS.FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE,
            productSubCategories.VENTILATION.DOUBLE_FLOW_VENTILATION,
            productSubCategories.VENTILATION.SIMPLE_FLOW_VENTILATION,
        ].includes(formState.subCategory)
    );
}

function canShowProductFinderByCategory(formState) {
    return formState?.category && typeOfCategoriesThatCanSearchProduct(formState);
}

function canShowHeatingPumpCoverageRateInput({ operationSheetName } = {}) {
    return [operationSheetNames.BAR_TH_159V_A50_4, operationSheetNames.BAR_TH_159V_A50_4_CDP].includes(operationSheetName);
}

function canShowPipeNominalDiameterInput({ operationSheetName, installationType } = {}) {
    return [operationSheetNames.BAR_TH_161V_A54_2].includes(operationSheetName) && installationType === operationInstallationTypes.EXCLUDING_PLATE_EXCHANGER;
}

function getBrandOfProductsOptions({ category, subCategory = '' }) {
    let brands = [];

    if (category && subCategory) brands = brandOfProducts[category][subCategory];
    if (category && !subCategory) brands = brandOfProducts[category];

    return brands.sort().map((value) => ({ label: value, value: value }));
}

function canShowProductFinderByCategoryAndSubCategory(formState) {
    return typeOfCategoriesWithSubCategoriesThatCanSearchProduct(formState) && typeOfSubCategoriesThatCanSearchProduct(formState);
}

function isProductThatCanShowProductFinder(formState) {
    return (
        (typeOfCategoriesThatCanSearchProduct(formState) && !formState.withoutProductAddedByRenolib) ||
        (typeOfCategoriesWithSubCategoriesThatCanSearchProduct(formState) && !formState.subCategory) ||
        (typeOfSubCategoriesThatCanSearchProduct(formState) && !formState.withoutProductAddedByRenolib)
    );
}

function getSelectVentilationClassOptions() {
    return ['A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'].map((ventilationClass) => ({ label: ventilationClass, value: ventilationClass }));
}

function canShowAdditionalHeatingPumpReferenceForm(product, operationSheetName) {
    if (product?.category !== productCategoriesValues.POMPE_CHALEUR || isNullishOrEmpty(product.detailsPerOperation)) return false;

    const operationDetails = product.detailsPerOperation[operationSheetName];
    if (isNullishOrEmpty(operationDetails)) return false;

    const { productDetailsToSpecify = [] } = operationDetails.details || {};
    const { compliant, compliancyMessages = [] } = operationDetails.compliancyByInstallationTypes?.NONE || {};

    return (
        [LABEL_INTERNAL_UNIT_REFERENCE, LABEL_EXTERNAL_UNIT_REFERENCE].some((label) => isNullishOrEmpty(productDetailsToSpecify.find((specifiedDetail) => specifiedDetail.label === label))) ||
        (!compliant && compliancyMessages.includes(LABEL_INTERNAL_UNIT_REFERENCE)) ||
        compliancyMessages.includes(LABEL_EXTERNAL_UNIT_REFERENCE)
    );
}

function getProductTooltipMessageForRenovationGlobal(renovationGlobalProduct) {
    if (isNullishOrEmpty(renovationGlobalProduct)) return '';

    const compliancyMessages = renovationGlobalProduct?.blockValidity?.errorMessages;

    const compliant = renovationGlobalProduct?.blockValidity?.compliant;

    if (!isProductWithValidStatus(renovationGlobalProduct?.product)) return PRODUCT_UNDER_VERIFICATION;

    return !compliant && compliancyMessages.length > 0 ? compliancyMessages[0] : '';
}

function isUpdatable(product, formToDisplay = 'default') {
    return formToDisplay === 'addOperations' || product.status === productStatuses.PENDING;
}

function isAToitureProduct(product) {
    return product.category === productCategoriesValues.TOITURE;
}

function canShowAdditionalTankForm(operationSheetName) {
    return [operationSheetNames.BAR_TH_143V_A51_5, operationSheetNames.BAR_TH_143V_A51_5_CDP].includes(operationSheetName);
}

function getSelectProductSpecifiedDetailPlaceholder(product, label) {
    const specifiedDetailValue = getDetailsProductByLabel(product, label);

    return (
        {
            ...heatingPumpUsagesTranslations,
        }[specifiedDetailValue] || specifiedDetailValue
    );
}

function getSelectHeatingPumpUsageOptions() {
    return Object.keys(heatingPumpUsagesTranslations).map((heatingPumpUsage) => {
        return { label: heatingPumpUsagesTranslations[heatingPumpUsage], value: heatingPumpUsage };
    });
}

function getSelectHeatingPumpRegulatorClassOptions() {
    return ['IV', 'V', 'VI', 'VII', 'VIII'].map((regulatorClass) => ({ label: regulatorClass, value: regulatorClass }));
}

function canShowAdditionalHeatingPumpInformationForm(product, operationSheetName) {
    if (
        ![operationSheetNames.BAR_TH_171V_A55_1, operationSheetNames.BAR_TH_171V_A55_1_CDP, operationSheetNames.BAR_TH_172V_A55_1, operationSheetNames.BAR_TH_172V_A55_1_CDP].includes(
            operationSheetName
        ) ||
        isNullishOrEmpty(product.detailsPerOperation)
    ) {
        return false;
    }

    const operationDetails = product.detailsPerOperation[operationSheetName];
    if (isNullishOrEmpty(operationDetails)) return false;

    const { productDetailsToSpecify = [] } = operationDetails.details || {};
    const { compliant, compliancyMessages = [] } = operationDetails.compliancyByInstallationTypes?.NONE || {};

    return (
        [LABEL_HEATING_PUMP_USAGE, LABEL_REGULARTOR_CLASS].some((label) => isNullishOrEmpty(productDetailsToSpecify.find((specifiedDetail) => specifiedDetail.label === label))) ||
        (!compliant && (compliancyMessages.includes(LABEL_HEATING_PUMP_USAGE) || compliancyMessages.includes(LABEL_REGULARTOR_CLASS)))
    );
}

function canShowAdditionalCombineSolarSystemInformationForm(product, operationSheetName) {
    if (![operationSheetNames.BAR_TH_143V_A51_5, operationSheetNames.BAR_TH_143V_A51_5_CDP].includes(operationSheetName) || isNullishOrEmpty(product.detailsPerOperation)) {
        return false;
    }

    const operationDetails = product.detailsPerOperation[operationSheetName];
    if (isNullishOrEmpty(operationDetails)) return false;

    const { productDetailsToSpecify = [] } = operationDetails.details || {};
    const { compliant, compliancyMessages = [] } = operationDetails.compliancyByInstallationTypes?.NONE || {};

    return (
        [LABEL_CAPTOR_BRAND, LABEL_CAPTOR_REFERENCE].some((label) => isNullishOrEmpty(productDetailsToSpecify.find((specifiedDetail) => specifiedDetail.label === label))) ||
        (!compliant && compliancyMessages.find((message) => message.includes(LABEL_CAPTOR_BRAND) || message.includes(LABEL_CAPTOR_REFERENCE)))
    );
}

function canShowAdditionnalVentilationInformationForm(product, operationSheetName) {
    if (operationSheetNames.BAR_TH_125V_A54_5 !== operationSheetName || isNullishOrEmpty(product.detailsPerOperation)) return false;

    const operationDetails = product.detailsPerOperation[operationSheetName];
    const energyEfficiencyDetails = product.energyEfficiencyProperties;
    if (isNullishOrEmpty(operationDetails)) return false;

    const { productDetailsToSpecify = [] } = operationDetails.details || {};
    const { compliant, compliancyMessages = [] } = operationDetails.compliancyByInstallationTypes?.NONE || {};

    if (product.installationType === operationInstallationTypes.INDIVIDUAL_INSTALLATION_MODULATED)
        return (
            [LABEL_REFERENCE_AVIS_TECHNIQUE, LABEL_DATE_VALIDITE_AVIS_TECHNIQUE].some((label) =>
                isNullishOrEmpty(productDetailsToSpecify.find((specifiedDetail) => specifiedDetail.label === label))
            ) ||
            isNullishOrEmpty(energyEfficiencyDetails.find((specifiedDetail) => specifiedDetail.label === LABEL_PUISSANCE_ELECTRIQUE)) ||
            (!compliant && compliancyMessages.includes(LABEL_PUISSANCE_ELECTRIQUE)) ||
            (!compliant &&
                (compliancyMessages.includes(LABEL_PUISSANCE_ELECTRIQUE) ||
                    compliancyMessages.includes(LABEL_REFERENCE_AVIS_TECHNIQUE) ||
                    compliancyMessages.includes(LABEL_DATE_VALIDITE_AVIS_TECHNIQUE)))
        );

    if (product.installationType === operationInstallationTypes.INDIVIDUAL_INSTALLATION_SELF_ADJUSTING)
        return (
            isNullishOrEmpty(energyEfficiencyDetails.find((specifiedDetail) => specifiedDetail.label === LABEL_PUISSANCE_ELECTRIQUE)) ||
            (!compliant && compliancyMessages.includes(LABEL_PUISSANCE_ELECTRIQUE))
        );

    return false;
}

const getSelectFunctioningTypeOptions = () => {
    return Object.keys(heatPumpTypesLabel).map((key) => ({
        value: key,
        label: heatPumpTypesLabel[key],
    }));
};

const getHeatPumpLabel = (heatPumpLabel) => {
    return heatPumpTypesLabel[heatPumpLabel];
};

function canShowExtraDescriptionForm(operation) {
    return [operationSheetNames.BAR_TH_173V_A56_1, operationSheetNames.BAR_TH_173V_A56_1_CDP].includes(operation.operationSheetName);
}

function isAThermicProduct(category) {
    return [
        productCategoriesValues.POMPE_CHALEUR,
        productCategoriesValues.CHAUDIERE_INDIVIDUELLE,
        productCategoriesValues.CHAUFFE_EAU_THERMODYNAMIQUE,
        productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS,
        productCategoriesValues.CHAUDIERE_BIOMASSE_INDIVIDUELLE,
        productCategoriesValues.CHAUFFE_EAU_SOLAIRE,
        productCategoriesValues.SYSTEME_SOLAIRE_COMBINE,
        productCategoriesValues.VENTILATION,
    ].includes(category);
}

function getDefaultProductQuantity(product, operation) {
    return isSingleProductInitialization(operation) ? 1 : product.quantity;
}

function isSingleProductInitialization({ operationSheetName } = {}) {
    return operationSheetName && operationSheetName.startsWith('BAR_TH');
}

function getReflectiveIsolantOptions() {
    return Object.keys(reflectiveIsolantTranslations).map((isReflectiveOption) => {
        return { label: reflectiveIsolantTranslations[isReflectiveOption], value: isReflectiveOption };
    });
}

function canShowAdditionalInsulatorInformationForm(product, operationSheetName) {
    if (product?.category !== productCategoriesValues.ISOLANT || isNullishOrEmpty(product.detailsPerOperation)) return false;

    const operationDetails = product.detailsPerOperation[operationSheetName];
    if (isNullishOrEmpty(operationDetails)) return false;

    const { productDetailsToSpecify = [] } = operationDetails.details || {};
    const { compliant, compliancyMessages = [] } = operationDetails.compliancyByInstallationTypes?.NONE || {};

    return (
        [LABEL_ISOLANT_REFLECTIVE].some((label) => isNullishOrEmpty(productDetailsToSpecify.find((specifiedDetail) => specifiedDetail.label === label))) ||
        (!compliant && compliancyMessages.includes(LABEL_ISOLANT_REFLECTIVE))
    );
}

export default {
    validateProductForm,
    getCategoryDefaultValue,
    getSelectOperationOptions,
    getExclusionDetailsToSpecifyValueByLabel,
    isHeatingPumpFunctioningTypeRequired,
    getSelectRegulatorClassOptions,
    getEnergyEfficiencyFieldLabel,
    isProductQuerySearchable,
    getAutocompleteProductsOptions,
    getSelectVatRateOptions,
    getSelectVatRatePlaceholder,
    validateMainProduct,
    validateOtherProduct,
    getAutocompleteOtherProductOptions,
    isUnitInputDisabled,
    areHeatingPumpRegulatorDetailsRequired,
    canCheckProductCompliancy,
    getProductCompliancy,
    getProductTooltipMessage,
    canShowGenericProductForm,
    validateOperationPrices,
    validateCategoryData,
    validateOtherProductForm,
    getDetailsProductByLabel,
    getSelectRegulatorClassPlaceholder,
    validatePartialFormProduct,
    formatFoundUpdates,
    canShowProductDetailsTitle,
    canShowCertificationInput,
    getProductVariationFormLabels,
    canShowProductVariations,
    isProductQuantityInputDisabled,
    isCalorifugeProduct,
    getEnergyEfficiencyUnit,
    extractEnergyEfficiencyValue,
    extractSpecifiedDetailValue,
    extractOperationDetails,
    canShowInstallationDescriptionInput,
    canShowNameInput,
    isMainProductSearchable,
    isMainProductSelected,
    getCreateProductModalTitle,
    canShowProductCreationSuccessDialog,
    isBrandInputDisabled,
    isReferenceInputDisabled,
    canShowSubCategorySelect,
    getSelectSubCategoryOptions,
    doesCategoryHaveSubCategories,
    canShowHeatingPumpFunctionningTypeCheckbox,
    canShowRegulatorForm,
    canShowOperationsPriceForm,
    getProductSubCategoriesForOperation,
    getSelectSheetMetalColorOptions,
    canShowProductDetailsForBarEn109OrBatEn109,
    canInitializeProductDetailsForBarEn109OrBatEn109,
    extractProductDetailsForBarEn109OrBatEn109FromRelatedOperation,
    isMainProductQuantityInputDisabled,
    isProductWithValidStatus,
    getSelectLayerTwoThicknessOptions,
    translateLayerTwoThickness,
    translateProductSheetMetalColor,
    canShowProductDetailsLayerTwo,
    canShowSolarFactorCalculationNote,
    getFunctioningTypeBySubCategory,
    findProductFileByType,
    isProductQuantityEqualToOne,
    canShowProductFinderByCategory,
    typeOfCategoriesThatCanSearchProduct,
    canShowHeatingPumpCoverageRateInput,
    getBrandOfProductsOptions,
    typeOfCategoriesWithSubCategoriesThatCanSearchProduct,
    canShowProductFinderByCategoryAndSubCategory,
    isProductThatCanShowProductFinder,
    getSelectVentilationClassOptions,
    isUpdatable,
    getProductTooltipMessageForRenovationGlobal,
    getProductSpecifiedDetail,
    extractOperationShortName,
    canShowAdditionalTankForm,
    getSelectProductSpecifiedDetailPlaceholder,
    getSelectHeatingPumpUsageOptions,
    getSelectHeatingPumpRegulatorClassOptions,
    canShowAdditionalHeatingPumpInformationForm,
    canShowAdditionalCombineSolarSystemInformationForm,
    canShowAdditionnalVentilationInformationForm,
    canShowPipeNominalDiameterInput,
    getSelectFunctioningTypeOptions,
    getHeatPumpLabel,
    canShowExtraDescriptionForm,
    isAThermicProduct,
    getDefaultProductQuantity,
    isAToitureProduct,
    getReflectiveIsolantOptions,
    canShowAdditionalInsulatorInformationForm,
    canShowAdditionalHeatingPumpReferenceForm,
};
