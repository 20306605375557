import {
    CREATE_UPLOAD_DOCUMENT_REQUEST,
    CREATE_UPLOAD_DOCUMENT_SUCCESS,
    CREATE_UPLOAD_DOCUMENT_FAILURE,
    GET_UPLOAD_DOCUMENTS_REQUEST,
    GET_UPLOAD_DOCUMENTS_SUCCESS,
    GET_UPLOAD_DOCUMENTS_FAILURE,
    GET_UPLOAD_DOCUMENT_REQUEST,
    GET_UPLOAD_DOCUMENT_SUCCESS,
    GET_UPLOAD_DOCUMENT_FAILURE,
    DELETE_UPLOAD_DOCUMENT_REQUEST,
    DELETE_UPLOAD_DOCUMENT_SUCCESS,
    DELETE_UPLOAD_DOCUMENT_FAILURE,
    DELETE_FILE_UPLOAD_DOCUMENT_REQUEST,
    DELETE_FILE_UPLOAD_DOCUMENT_FAILURE,
    DELETE_FILE_UPLOAD_DOCUMENT_SUCCESS,
    GET_CHECK_DOCUMENT_SUCCESS,
    GET_CHECK_DOCUMENT_FAILURE,
    GET_CHECK_DOCUMENT_REQUEST,
    RESET_DOCUMENT_CHECK_MESSAGE,
    UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_FAILURE,
    UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_REQUEST,
    UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_SUCCESS,
} from '../types/upload-document.types';
import defaultUploadDocumentService from '../services/upload-document.service';
import formActions from '../actions/ui/form';
import dialogsActions from '../actions/ui/dialogs';
import dossierCEEActions from './dossier-cee.actions';
import { handleErrorResponse, parseError } from '../../api';
import history from '../../utils/history';
import { otherDocumentTypes } from '../../utils/enums';
import { ADVANCE_PAYMENT_AGREEMENT_MESSAGE, UPLOAD_DOCUMENT_CHECK_REPORT_UPDATED } from '../../utils/messages';
import dossierCEEHelper from '../../utils/dossier-cee-helper';

export const createCadastralPlotDocument =
    (uploadDocumentService = defaultUploadDocumentService) =>
    ({ formData, onSuccess, redirect = undefined, hasAccessToNewView }, organization) => {
        return (dispatch, getState) => {
            dispatch({ type: CREATE_UPLOAD_DOCUMENT_REQUEST });
            dispatch(formActions.startRequesting());
            const { account = {}, token } = getState().auth;
            const accountId = getState().auth.account._id;
            formData.append('accountId', accountId);
            return uploadDocumentService
                .createParcelCadastralUploadDocument(formData, organization)
                .then(({ data: uploadDocument }) => {
                    if (onSuccess) onSuccess();
                    dispatch({ type: CREATE_UPLOAD_DOCUMENT_SUCCESS, payload: { uploadDocument } });
                    dispatch(formActions.stopRequesting());
                    const { dossierCEE } = uploadDocument;
                    dispatch(dossierCEEActions.updateDossierCEELocally({ dossierCEEUUID: dossierCEE.uuid, updates: dossierCEE }));

                    const { organization = {} } = getState().organization;
                    const canShowAdvancePaymentAgreementDialog = dossierCEEHelper.canShowAdvancePaymentAgreementDialog(dossierCEE, organization);
                    if (!hasAccessToNewView && canShowAdvancePaymentAgreementDialog) {
                        dispatch(
                            dialogsActions.showConfirmDialog({
                                message: ADVANCE_PAYMENT_AGREEMENT_MESSAGE,
                                closable: false,
                                withChoice: true,
                                includeValidationIcon: true,
                                onConfirm() {
                                    dispatch(dossierCEEActions.saveAdvancePaymentAgreementResponse({ dossierCEEUUID: dossierCEE.uuid, energySavingCertificatePremiumAdvancePaymentAgreed: true }));
                                },
                                onCancel() {
                                    dispatch(dossierCEEActions.saveAdvancePaymentAgreementResponse({ dossierCEEUUID: dossierCEE.uuid, energySavingCertificatePremiumAdvancePaymentAgreed: false }));
                                },
                            })
                        );
                    } else {
                        dispatch(dialogsActions.showSuccessDialog({ message: 'Votre document a bien été enregistré', redirect: redirect }));
                    }
                    if (hasAccessToNewView) {
                        window.location.href = `${process.env.REACT_APP_FRONT_V2}/dossiers-financement/${dossierCEE.uuid}?token=${token}&uuidOrganization=${account.uuidOrganization}&accountUUID=${account.uuid}`;
                        return;
                    }
                    return uploadDocument;
                })
                .catch((error) => {
                    const errorMessage = parseError(error);
                    dispatch({ type: CREATE_UPLOAD_DOCUMENT_FAILURE, payload: { errorMessage } });
                    dispatch(formActions.stopRequesting());
                    dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                });
        };
    };

const createUploadDocumentInDossierCEE =
    (uploadDocumentService = defaultUploadDocumentService) =>
    ({ formData, onSuccess, hasAccessToNewView, organization }) => {
        return (dispatch, getState) => {
            const { account = {}, token } = getState().auth;
            dispatch({ type: CREATE_UPLOAD_DOCUMENT_REQUEST });
            dispatch(formActions.startRequesting());

            const accountId = getState().auth.account._id;
            formData.append('accountId', accountId);

            return uploadDocumentService
                .createUploadDocument(formData, organization)
                .then(({ data: uploadDocument }) => {
                    dispatch({ type: CREATE_UPLOAD_DOCUMENT_SUCCESS, payload: { uploadDocument } });
                    dispatch(formActions.stopRequesting());
                    const { dossierCEE } = uploadDocument;
                    const { organization = {} } = getState().organization;
                    dispatch(dossierCEEActions.getDossierCEE({ dossierCEEUUID: uploadDocument.dossierCEE.uuid }));

                    const canShowAdvancePaymentAgreementDialog = dossierCEEHelper.canShowAdvancePaymentAgreementDialog(dossierCEE, organization);
                    if (!hasAccessToNewView && canShowAdvancePaymentAgreementDialog) {
                        dispatch(
                            dialogsActions.showConfirmDialog({
                                message: ADVANCE_PAYMENT_AGREEMENT_MESSAGE,
                                closable: false,
                                withChoice: true,
                                includeValidationIcon: true,
                                onConfirm() {
                                    dispatch(
                                        dossierCEEActions.saveAdvancePaymentAgreementResponse({
                                            dossierCEEUUID: dossierCEE.uuid,
                                            energySavingCertificatePremiumAdvancePaymentAgreed: true,
                                        })
                                    );
                                },
                                onCancel() {
                                    dispatch(
                                        dossierCEEActions.saveAdvancePaymentAgreementResponse({
                                            dossierCEEUUID: dossierCEE.uuid,
                                            energySavingCertificatePremiumAdvancePaymentAgreed: false,
                                        })
                                    );
                                },
                            })
                        );
                        return uploadDocument;
                    }
                    if (hasAccessToNewView) {
                        window.location.href = `${process.env.REACT_APP_FRONT_V2}/dossiers-financement/${dossierCEE.uuid}?token=${token}&uuidOrganization=${account.uuidOrganization}&accountUUID=${account.uuid}`;
                        return;
                    }
                    dispatch({ type: CREATE_UPLOAD_DOCUMENT_SUCCESS, payload: { uploadDocument } });
                    dispatch(formActions.stopRequesting());
                    dispatch(dossierCEEActions.getDossierCEE({ dossierCEEUUID: uploadDocument.dossierCEE.uuid }));
                    if (onSuccess) onSuccess();

                    return uploadDocument;
                })
                .catch((error) => {
                    const errorMessage = parseError(error);
                    dispatch({ type: CREATE_UPLOAD_DOCUMENT_FAILURE, payload: { errorMessage } });
                    dispatch(formActions.stopRequesting());
                    dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                });
        };
    };

const createUploadDocument =
    (uploadDocumentService = defaultUploadDocumentService) =>
    ({ formData, onSuccess, hasAccessToNewView, organization }) => {
        return (dispatch, getState) => {
            const { account = {}, token } = getState().auth;
            dispatch({ type: CREATE_UPLOAD_DOCUMENT_REQUEST });
            dispatch(formActions.startRequesting());

            const accountId = getState().auth.account._id;
            formData.append('accountId', accountId);
            uploadDocumentService
                .createUploadDocument(formData, organization)
                .then(({ data: uploadDocument }) => {
                    dispatch({ type: CREATE_UPLOAD_DOCUMENT_SUCCESS, payload: { uploadDocument } });
                    dispatch(formActions.stopRequesting());

                    const { dossierCEE } = uploadDocument;
                    dispatch(dossierCEEActions.updateDossierCEELocally({ dossierCEEUUID: dossierCEE.uuid, updates: dossierCEE }));

                    const { organization = {} } = getState().organization;

                    const canShowAdvancePaymentAgreementDialog = dossierCEEHelper.canShowAdvancePaymentAgreementDialog(dossierCEE, organization);

                    if (!hasAccessToNewView && canShowAdvancePaymentAgreementDialog) {
                        dispatch(
                            dialogsActions.showConfirmDialog({
                                message: ADVANCE_PAYMENT_AGREEMENT_MESSAGE,
                                closable: false,
                                withChoice: true,
                                includeValidationIcon: true,
                                onConfirm() {
                                    dispatch(dossierCEEActions.saveAdvancePaymentAgreementResponse({ dossierCEEUUID: dossierCEE.uuid, energySavingCertificatePremiumAdvancePaymentAgreed: true }));
                                },
                                onCancel() {
                                    dispatch(dossierCEEActions.saveAdvancePaymentAgreementResponse({ dossierCEEUUID: dossierCEE.uuid, energySavingCertificatePremiumAdvancePaymentAgreed: false }));
                                },
                            })
                        );
                    } else {
                        dispatch(dialogsActions.showSuccessDialog({ message: 'Votre document a bien été enregistré' }));
                    }
                    if (hasAccessToNewView) {
                        window.location.href = `${process.env.REACT_APP_FRONT_V2}/dossiers-financement/${dossierCEE.uuid}?token=${token}&uuidOrganization=${account.uuidOrganization}&accountUUID=${account.uuid}`;
                        return;
                    }
                    if (onSuccess) onSuccess();
                })
                .catch(handleErrorResponse({ dispatch, errorType: CREATE_UPLOAD_DOCUMENT_FAILURE }));
        };
    };

const getUploadDocuments =
    (uploadDocumentService = defaultUploadDocumentService) =>
    (query) => {
        return (dispatch) => {
            dispatch({ type: GET_UPLOAD_DOCUMENTS_REQUEST });
            if (!query.searchString) dispatch(formActions.startRequesting());
            uploadDocumentService
                .getUploadDocuments(query)
                .then(({ data }) => {
                    dispatch(formActions.stopRequesting());
                    dispatch({ type: GET_UPLOAD_DOCUMENTS_SUCCESS, payload: { uploadDocuments: data.filter(({ documentType }) => documentType !== otherDocumentTypes.SOLAR_CERTIFICATION) } });
                })
                .catch((error) => {
                    const errorMessage = parseError(error);
                    dispatch(formActions.stopRequesting());
                    dispatch({ type: GET_UPLOAD_DOCUMENTS_FAILURE, payload: { errorMessage } });
                });
        };
    };

const getUploadDocument =
    (uploadDocumentService = defaultUploadDocumentService) =>
    ({ uploadDocumentUUID, organization }) => {
        return (dispatch) => {
            dispatch({ type: GET_UPLOAD_DOCUMENT_REQUEST });

            uploadDocumentService
                .getUploadDocument(uploadDocumentUUID, organization)
                .then(({ data }) => {
                    dispatch({ type: GET_UPLOAD_DOCUMENT_SUCCESS, payload: { uploadDocument: data } });
                })
                .catch((error) => {
                    const errorMessage = parseError(error);
                    dispatch({ type: GET_UPLOAD_DOCUMENT_FAILURE, payload: { errorMessage } });
                    dispatch(dialogsActions.showFailureDialog({ message: errorMessage, redirect: '/documents' }));
                });
        };
    };

const checkIfDocumentExists =
    (uploadDocumentService = defaultUploadDocumentService) =>
    ({ documentType, value, operationUUID }) => {
        return (dispatch) => {
            dispatch({ type: GET_CHECK_DOCUMENT_REQUEST });
            return uploadDocumentService
                .checkIfDocumentAlreadyExists(value, documentType, operationUUID)
                .then(({ data }) => {
                    dispatch({ type: GET_CHECK_DOCUMENT_SUCCESS, payload: { message: data } });
                })
                .catch((errorMessage) => {
                    dispatch({ type: GET_CHECK_DOCUMENT_FAILURE, payload: { errorMessage } });
                });
        };
    };

const deleteUploadDocument =
    (uploadDocumentService = defaultUploadDocumentService) =>
    ({ uploadDocumentUUID, goBackToDocumentsPage = false, onSuccessDelete }) => {
        return (dispatch, getState) => {
            dispatch({ type: DELETE_UPLOAD_DOCUMENT_REQUEST });
            dispatch(formActions.startRequesting());

            const accountUUID = getState().auth.account.uuid;

            return uploadDocumentService
                .deleteUploadDocument(uploadDocumentUUID, accountUUID)
                .then(({ data }) => {
                    dispatch({ type: DELETE_UPLOAD_DOCUMENT_SUCCESS, payload: { uploadDocument: data, goBackToDocumentsPage } });
                    dispatch(formActions.stopRequesting());
                    onSuccessDelete && onSuccessDelete();
                    if (goBackToDocumentsPage) history.push('/documents');
                })
                .catch((error) => {
                    const errorMessage = parseError(error);
                    dispatch(formActions.stopRequesting());
                    dispatch({ type: DELETE_UPLOAD_DOCUMENT_FAILURE, payload: { errorMessage } });
                    dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                });
        };
    };

function deleteFileFromUploadDocument(uploadDocumentService = defaultUploadDocumentService) {
    return function ({ uploadDocumentUUID, fileUUID }) {
        return (dispatch) => {
            dispatch({ type: DELETE_FILE_UPLOAD_DOCUMENT_REQUEST });
            dispatch(formActions.startRequesting());

            return uploadDocumentService
                .deleteFileFromUploadDocument({ uploadDocumentUUID, fileUUID })
                .then(({ data }) => {
                    dispatch({ type: DELETE_FILE_UPLOAD_DOCUMENT_SUCCESS, payload: { uploadDocument: data } });
                    dispatch(formActions.stopRequesting());
                })
                .catch((error) => {
                    const errorMessage = parseError(error);
                    dispatch(formActions.stopRequesting());
                    dispatch({ type: DELETE_FILE_UPLOAD_DOCUMENT_FAILURE, payload: { errorMessage } });
                    dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                });
        };
    };
}

function resetDocumentCheckMessage() {
    return { type: RESET_DOCUMENT_CHECK_MESSAGE };
}

function updateUploadDocumentCheckReport({ uploadDocumentUUID, checkReport, dossier, onSuccess }) {
    return (dispatch) => {
        dispatch(formActions.startRequesting());
        dispatch({ type: UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_REQUEST });

        defaultUploadDocumentService
            .updateUploadDocumentCheckReport(dossier.organization, { uploadDocumentUUID, checkReport })
            .then(({ data: uploadDocument }) => {
                dispatch({ type: UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_SUCCESS, payload: { uploadDocument } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: UPLOAD_DOCUMENT_CHECK_REPORT_UPDATED }));
                if (onSuccess) onSuccess();
            })
            .catch(handleErrorResponse({ dispatch, errorType: UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_FAILURE }));
    };
}

export default {
    createUploadDocument,
    getUploadDocuments,
    getUploadDocument,
    deleteUploadDocument,
    deleteFileFromUploadDocument,
    createUploadDocumentInDossierCEE,
    checkIfDocumentExists,
    resetDocumentCheckMessage,
    updateUploadDocumentCheckReport,
};
