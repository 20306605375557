export const INVOICE_NOT_SENDABLE_AND_PAYED = 'Cette facture a déjà été payée et ne peut plus être envoyée.';
export const INVOICE_NOT_SENDABLE_AND_CANCELLED_OR_RECTIFIED = 'Cette facture a été annulée ou rectifiée et ne peut plus être envoyée.';
export const INVOICE_NOT_SENDABLE_AND_DRAFT = "Merci de finaliser la facture avant de l'envoyer.";

export const INVOICE_NOT_PAYABLE_AND_DRAFT = 'Merci de finaliser la facture avant de confirmer le paiement.';
export const INVOICE_NOT_PAYABLE_AND_FINALIZED = "Merci d'envoyer la facture avant de confirmer le paiement.";
export const INVOICE_NOT_PAYABLE_AND_CANCELLED_OR_RECTIFIED = 'Cette facture a été annulée ou rectifiée et ne peut plus être envoyée.';
export const INVOICE_ALREADY_PAID = 'Cette facture a déjà été déclarée comme payée.';
export const INVOICE_NOT_PAYABLE_AND_FINALIZED_AND_TYPE_IS_NOT_CLASSIC = "Une facture d'avoir ne peut pas être payée.";

export const INVOICE_NOT_CANCELLABLE_AND_FINALIZED = 'Vous ne pouvez pas supprimer une facture finalisée.';
export const INVOICE_ALREADY_CANCELLED = 'Cette facture a déjà été annulée ou rectifiée.';
export const INVOICE_HAS_A_SENT_DOSSIER_CEE =
    'Cette facture fait partie d’un dossier envoyé à votre Obligé. Elle ne peut plus être rectifiée ni annulée. Pour plus d’information, merci de contacter le service client à support-pro@renolib.fr.';
export const INVOICE_MUST_BE_SAVED = 'Veuiller enregistrer la facture.';

export const NO_SIGNED_QUOTATIONS_FOR_INVOICE = "Vous n'avez pas de devis signé en attente de facturation.";
export const NO_INVOICES = "Aucune facture n'a été créée.";

export const NO_INVOICES_TO_SEND = 'Aucune facture à envoyer.';

export const NO_EXPORTABLE_INVOICES = 'Vous devez finaliser au moins une facture avant de pouvoir utiliser cette fonctionnalité.';

export const CONFIRM_CANCEL_INVOICE_DIALOG = 'Une nouvelle facture va être générée suite à l’annulation de votre facture n°';

export const ONE_EURO_INVOICE_BTN = 'Calculer la facture à 1 €';

export const INVOICE_DELETED = 'La facture a bien été supprimée.';
export const INVOICE_SAVED = 'Votre facture a bien été enregistrée.';
export const INVOICE_FINALIZED = 'Votre facture a bien été finalisée.';
export const INVOICE_SENT = 'Votre facture a bien été envoyée.';
export const INVOICE_PAID = 'Le paiement de votre facture a bien été enregistré.';
export const INVOICE_RECTIFIED = 'Votre facture a bien été rectifiée.';

export const INVOICE_CLASSIC_WORDING = 'Numéro de la facture';
export const INVOICE_ADVANCE_PAYMENT_WORDING = "Numéro de la facture d'acompte";

export const COMMERCIAL_DISCOUNT = 'Remise commerciale';

export const INVOICE_RGE_CERTIFICATES_EXPIRED_MESSAGE =
    "Êtes-vous sûr(e) de vouloir enregistrer votre facture avec un sous-traitant dont le certificat RGE n’est pas éligible aux financements CEE ou MaPrimeRénov' ?";
export const INVOICE_NOT_FINALIZABLE_PRODUCTS_NOT_VALIDATED = "Un ou plusieurs produit(s) utilisé(s) sur cette facture n'a pas encore été validé. Cette facture ne peut donc pas être finalisée.";
export const INVOICE_NOT_FINALIZABLE_OPERATION_NOT_VALIDATED = "Une ou plusieurs operation(s) utilisé(s) sur cette facture n'a pas encore été validé. Cette facture ne peut donc pas être finalisée.";
export const BENEFICIARY_CONTROL_FORM_MESSAGE =
    'Merci d’avoir finalisé la facture. Pour valider la conformité, il est maintenant nécessaire d’avoirenvoyer un formulaire de contrôle au bénéficiaire afin de confirmer de l’exactitude des informations du dossier. Cette étape garantit la conformité administrative de nos dossiers et le respect des normes pour tous les professionnels.';
