import _ from 'lodash';
import {
    CREATE_INVOICE_REQUEST,
    CREATE_INVOICE_SUCCESS,
    CREATE_INVOICE_FAILURE,
    GET_INVOICE_REQUEST,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_FAILURE,
    SAVE_INVOICE_REQUEST,
    SAVE_INVOICE_SUCCESS,
    SAVE_INVOICE_FAILURE,
    GET_INVOICES_REQUEST,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_FAILURE,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAILURE,
    DELETE_INVOICE_REQUEST,
    CANCEL_INVOICE_SUCCESS,
    CANCEL_INVOICE_FAILURE,
    CANCEL_INVOICE_REQUEST,
    UPDATE_INVOICE_SUCCESS,
    UPDATE_INVOICE_FAILURE,
    UPDATE_INVOICE_REQUEST,
    UPDATE_INVOICE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_INSTALLATION_END_DATE,
    UPDATE_OPERATION_PRODUCT_QUANTITY,
    UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY,
    UPDATE_INVOICE_ISSUE_DATE_REQUEST,
    UPDATE_INVOICE_OTHER_PRODUCT,
    UPDATE_INVOICE_MAIN_PRODUCT,
    ADD_INVOICE_OTHER_PRODUCT,
    DELETE_INVOICE_OTHER_PRODUCT,
    SEND_INVOICE_BY_EMAIL_REQUEST,
    SEND_INVOICE_BY_EMAIL_SUCCESS,
    SEND_INVOICE_BY_EMAIL_FAILURE,
    ADD_MAIN_PRODUCT_VARIATION_INVOICE,
    UPDATE_MAIN_PRODUCT_VARIATION_INVOICE,
    DELETE_MAIN_PRODUCT_VARIATION_INVOICE,
    UPDATE_INVOICE_OPERATION,
    GET_INVOICES_ANALYTICS_SUCCESS,
    GET_INVOICES_ANALYTICS_REQUEST,
    GET_INVOICES_ANALYTICS_FAILURE,
    UPDATE_INVOICE_WASTE_MANAGEMENT,
    UPDATE_INVOICE_OPERATIONS,
    UPDATE_ADVANCE_PAYMENT_VAT_RATE,
    UPDATE_INVOICE_OPERATION_TECHNICIAN,
    UPDATE_INVOICE_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT,
    RECTIFY_INVOICE_REQUEST,
    RECTIFY_INVOICE_SUCCESS,
    RECTIFY_INVOICE_FAILURE,
    ACQUIT_INVOICE_REQUEST,
    ACQUIT_INVOICE_SUCCESS,
    ACQUIT_INVOICE_FAILURE,
    UPDATE_INVOICE_MAR,
    CHECK_INVOICE_DOCUMENTS_GENERATION_REQUEST,
    CHECK_INVOICE_DOCUMENTS_GENERATION_SUCCESS,
    CHECK_INVOICE_DOCUMENTS_GENERATION_FAILURE,
    UPDATE_INVOICE_OPERATION_PRODUCT,
    UPDATE_INVOICE_PRODUCT_REQUEST,
    UPDATE_INVOICE_PRODUCT_SUCCESS,
    UPDATE_INVOICE_PRODUCT_FAILURE,
} from '../types/invoice.types';
import invoiceService from '../services/invoice.service';
import { handleErrorResponse, parseError } from '../../api';
import formActions from './ui/form';
import dialogsActions from './ui/dialogs';
import history from '../../utils/history';
import { productTypes } from '../../utils/enums';
import { v1 as uuidV1 } from 'uuid';

import operationHelper from '../../utils/operation-helper';
import { ASYNC_JOBS_STILL_IN_PROGRESS } from '../../utils/messages';
import asyncJobHelper from '../../utils/asyncJob.helper';
import productCatalogueService from '../services/product-catalogue.service';
import dialogActions from './ui/dialogs';

import React from 'react';
import { BuyMoreFolders } from '../../components/helpers/dialogs/customDialogs/BuyMoreFolders';
import { createCheckoutSingleFolders } from '../services/subscription.service';

const createInvoice =
    (datas, { onError }, { historyState } = {}) =>
    (dispatch) => {
        dispatch({ type: CREATE_INVOICE_REQUEST });
        dispatch(formActions.startRequesting());
        invoiceService
            .createInvoice(datas)
            .then(({ data }) => {
                dispatch({ type: CREATE_INVOICE_SUCCESS, payload: data });
                dispatch(formActions.stopRequesting());
                const origin = _.get(historyState, 'origin');
                const originMessage = _.get(historyState, 'originMessage');
                if (origin && originMessage) {
                    history.push(`/factures/${data.uuid}/edition?origin=${origin}&originMessage=${originMessage}`, historyState);
                } else {
                    history.push(`/factures/${data.uuid}/edition`);
                }
            })
            .catch((error) => {
                if (onError) onError();
                const errorMessage = parseError(error);
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                dispatch({ type: CREATE_INVOICE_FAILURE, error: errorMessage });
                dispatch(formActions.stopRequesting());
            });
    };

const getInvoice = (uuidInvoice) => (dispatch) => {
    dispatch({ type: GET_INVOICE_REQUEST });
    dispatch(formActions.startRequesting());
    invoiceService
        .getInvoice(uuidInvoice)
        .then(({ data }) => {
            dispatch({ type: GET_INVOICE_SUCCESS, payload: data });
            dispatch(formActions.stopRequesting());
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage, redirect: '/factures' }));
            dispatch({ type: GET_INVOICE_FAILURE, error: errorMessage });
            dispatch(formActions.stopRequesting());
        });
};

const onCreateSingleFoldersCheckout = (params) => (dispatch) => {
    dispatch(formActions.startRequesting());
    const url = window.location.href;
    return createCheckoutSingleFolders({ return_url: url })
        .then(({ data }) => {
            dispatch(formActions.stopRequesting());
            window.location = data;
            return;
        })
        .catch(() => {
            dispatch(formActions.stopRequesting());
            dispatch(dialogsActions.showFailureDialog({ message: "Erreur lors de la creation du parcours d'achat" }));
        });
};

const saveInvoice =
    ({ uuidInvoice, updates, loading = false, onOpenModalToGenerateMovingCertificate, historyState }) =>
    (dispatch, getState) => {
        dispatch({ type: SAVE_INVOICE_REQUEST });
        if (loading) dispatch(formActions.startRequesting());
        invoiceService
            .saveInvoice(uuidInvoice, updates)
            .then(({ data: invoice }) => {
                if (invoice?.specialActions && invoice.specialActions.includes('PAY_MORE')) {
                    if (loading) dispatch(formActions.stopRequesting());
                    dispatch(
                        dialogActions.showConfirmDialog({
                            renderCustomComponent: () => <BuyMoreFolders />,
                            withChoice: true,
                            onConfirm: () => dispatch(onCreateSingleFoldersCheckout()),
                            onReject: () => (window.location.href = `${process.env.REACT_APP_FRONT_V2}/offres`),
                            closable: true,
                            rejectButtonChoice: 'Changer d’offre',
                            confirmButtonChoice: 'Payer un dossier',
                        })
                    );
                    return;
                }
                dispatch({ type: SAVE_INVOICE_SUCCESS, payload: invoice });
                if (loading) dispatch(formActions.stopRequesting());
                if (updates.finalizeInvoice && !invoice?.isDifferentOrganizationAddressFromQuotation) {
                    const origin = _.get(historyState, 'origin');
                    const originMessage = _.get(historyState, 'originMessage');
                    if (origin && originMessage) {
                        history.push(`/factures/${invoice.uuid}/apercu?origin=${origin}&originMessage=${originMessage}`);
                    } else {
                        history.push(`/factures/${invoice.uuid}/apercu`);
                    }
                    dispatch({ type: UPDATE_INVOICE_SUCCESS, payload: invoice });
                }
                if (updates.finalizeInvoice && invoice?.isDifferentOrganizationAddressFromQuotation && !invoice?.movingCertificate.fileDownloadUrl) onOpenModalToGenerateMovingCertificate();
            })
            .catch((error) => {
                const errorMessage = parseError(error);

                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                dispatch({ type: SAVE_INVOICE_FAILURE });
                if (loading) dispatch(formActions.stopRequesting());
            });
    };

const getInvoices =
    (query = {}) =>
    (dispatch) => {
        dispatch({ type: GET_INVOICES_REQUEST });
        if (!query.searchString) dispatch(formActions.startRequesting());
        invoiceService
            .getInvoices(query)
            .then(({ data }) => {
                dispatch({ type: GET_INVOICES_SUCCESS, payload: data });
                dispatch(formActions.stopRequesting());
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_INVOICES_FAILURE, error: errorMessage });
                dispatch(formActions.stopRequesting());
            });
    };

const deleteInvoice = (uuidInvoice) => (dispatch) => {
    dispatch({ type: DELETE_INVOICE_REQUEST });
    dispatch(formActions.startRequesting());
    invoiceService
        .deleteInvoice(uuidInvoice)
        .then(({ data }) => {
            dispatch({ type: DELETE_INVOICE_SUCCESS, payload: data });
            dispatch(formActions.stopRequesting());
            history.push('/factures');
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            dispatch({ type: DELETE_INVOICE_FAILURE });
            dispatch(formActions.stopRequesting());
        });
};

function cancelInvoice(uuidInvoice, params) {
    return (dispatch) => {
        dispatch({ type: CANCEL_INVOICE_REQUEST });
        dispatch(formActions.startRequesting());
        invoiceService
            .cancelInvoice(uuidInvoice, params)
            .then(({ data: { invoice } }) => {
                dispatch({ type: CANCEL_INVOICE_SUCCESS, payload: { invoice } });
                dispatch(formActions.stopRequesting());
                history.push(`/factures/${invoice.uuid}/apercu`);
            })
            .catch(handleErrorResponse({ dispatch, errorType: CANCEL_INVOICE_FAILURE }));
    };
}

const updateInvoiceStatusToPaid =
    (uuidInvoice, updates, onSuccess = () => getInvoices()) =>
    (dispatch) => {
        dispatch({
            type: UPDATE_INVOICE_REQUEST,
        });
        invoiceService
            .updateInvoiceStatusToPaid(uuidInvoice, updates)
            .then(({ data }) => {
                dispatch({ type: UPDATE_INVOICE_SUCCESS, payload: data });
                dispatch(
                    dialogsActions.showSuccessDialog({
                        message: 'Votre modification a bien été enregistrée',
                    })
                );
                dispatch(onSuccess());
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                dispatch({ type: UPDATE_INVOICE_FAILURE });
                dispatch(formActions.stopRequesting());
            });
    };

const sendInvoiceByEmail =
    ({ invoiceUUID, sendingData }) =>
    (dispatch) => {
        dispatch({ type: SEND_INVOICE_BY_EMAIL_REQUEST });
        dispatch(formActions.startRequesting());

        invoiceService
            .sendInvoiceByEmail(invoiceUUID, sendingData)
            .then(({ data }) => {
                dispatch({ type: SEND_INVOICE_BY_EMAIL_SUCCESS, payload: { invoice: data } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre facture a bien été envoyée' }));
                dispatch(getInvoices({ sort: { createdAt: -1 } }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                dispatch({ type: SEND_INVOICE_BY_EMAIL_FAILURE, payload: { errorMessage } });
            });
    };

const updateOperationInstallationDate = ({ uuidOperation, installationDate, additionalRelatedOperations = [] }) => {
    return {
        type: UPDATE_INVOICE_OPERATION_INSTALLATION_DATE,
        payload: { uuidOperation, installationDate, additionalRelatedOperations },
    };
};

const updateOperationInstallationEndDate = ({ uuidOperation, installationEndDate, additionalRelatedOperations = [] }) => {
    return {
        type: UPDATE_OPERATION_INSTALLATION_END_DATE,
        payload: { uuidOperation, installationEndDate, additionalRelatedOperations },
    };
};

function updateWasteManagement({ wasteManagement }) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_INVOICE_WASTE_MANAGEMENT,
            payload: { wasteManagement },
        });
    };
}

function updateMar({ name, value }) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_INVOICE_MAR,
            payload: { name, value },
        });
    };
}

const updateOperationProductQuantity = ({ uuidOperation, uuidProduct, quantity, additionalOperations = [] }) => {
    return {
        type: UPDATE_OPERATION_PRODUCT_QUANTITY,
        payload: { uuidOperation, uuidProduct, quantity, additionalOperations },
    };
};

function updateOperationProduct({ uuidOperation, uuidProduct, updates = {} } = {}) {
    return {
        type: UPDATE_INVOICE_OPERATION_PRODUCT,
        payload: { uuidOperation, uuidProduct, ...updates },
    };
}

function setTotalNetToOneEuro() {
    return (dispatch, getState) => {
        const { invoice } = getState().invoicesState;
        dispatch({ type: UPDATE_INVOICE_OPERATIONS, payload: { operations: operationHelper.calculateOperationPremiumForEachOperationForOneDollarNetPrice(invoice) } });
    };
}

const updateOperationOtherProductQuantity = ({ uuidOperation, uuidProduct, quantity }) => {
    return {
        type: UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY,
        payload: { uuidOperation, uuidProduct, quantity },
    };
};

const updateInvoiceIssueDate = ({ invoiceIssueDate }) => {
    return {
        type: UPDATE_INVOICE_ISSUE_DATE_REQUEST,
        payload: { invoiceIssueDate },
    };
};

const updateOtherProduct = ({ operationUUID, productUUID, updates }) => {
    const completedUpdates = updates;
    if (updates.product) {
        completedUpdates.price = updates.product.price;
        completedUpdates.vatRate = updates.product.vatRate;
        completedUpdates.unit = updates.product.unit;
    }
    return {
        type: UPDATE_INVOICE_OTHER_PRODUCT,
        payload: { operationUUID, productUUID, updates: completedUpdates },
    };
};

const resetOtherProduct = ({ operationUUID, productUUID }) => {
    return updateOtherProduct({ operationUUID, productUUID, updates: { product: { type: productTypes.OTHER_PRODUCT } } });
};

const updateMainProduct =
    ({ operationUUID, updates, productUUID }, { setPricingProperties = true } = {}) =>
    (dispatch, getState) => {
        const completedUpdates = updates;
        if (updates.product && updates.product.detailsPerOperation && setPricingProperties) {
            const { operations } = getState().invoicesState.invoice;
            const { operationSheetName } = operations.find(({ uuid }) => uuid === operationUUID);
            completedUpdates.price = updates.product.detailsPerOperation[operationSheetName].defaultPrice;
        }

        dispatch({
            type: UPDATE_INVOICE_MAIN_PRODUCT,
            payload: { operationUUID, updates: completedUpdates, productUUID },
        });
    };

function updateNonEnergyRenovationOperationMainProduct({ operationUUID, updates }) {
    return {
        type: UPDATE_INVOICE_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT,
        payload: { operationUUID, updates },
    };
}

const updateLastOtherProduct =
    ({ operationUUID, updates }) =>
    (dispatch, getState) => {
        const { operations } = getState().invoicesState.invoice;
        const { products } = operations.find(({ uuid }) => uuid === operationUUID);
        const otherProducts = products.filter(({ product }) => product.type === productTypes.OTHER_PRODUCT);
        const lastOtherProduct = otherProducts[otherProducts.length - 1];

        dispatch(updateOtherProduct({ operationUUID, productUUID: lastOtherProduct.uuid, updates }));
    };

const initProduct = (type) => {
    const productInitData = {
        uuid: uuidV1(),
        blockValidity: { valid: false, errorMessages: ['Veuillez sélectionner un produit'] },
        product: { type },
    };
    if (type === productTypes.OTHER_PRODUCT) productInitData.unit = 'u';

    return productInitData;
};

const addOtherProduct = ({ operationUUID, operationOfRenovationGlobalUUID = undefined, productInitData = {} }) => {
    return {
        type: ADD_INVOICE_OTHER_PRODUCT,
        payload: { operationUUID, product: { ...initProduct(productTypes.OTHER_PRODUCT), ...productInitData }, operationOfRenovationGlobalUUID },
    };
};

const deleteOtherProduct = ({ operationUUID, productUUID, operationOfRenovationGlobalUUID }) => {
    return {
        type: DELETE_INVOICE_OTHER_PRODUCT,
        payload: { operationUUID, productUUID, operationOfRenovationGlobalUUID },
    };
};

function addMainProductVariation({ operationUUID }) {
    return { type: ADD_MAIN_PRODUCT_VARIATION_INVOICE, payload: { operationUUID, variation: { uuid: uuidV1() } } };
}

function updateMainProductVariation({ operationUUID, variationUUID, updates = {} } = {}) {
    return { type: UPDATE_MAIN_PRODUCT_VARIATION_INVOICE, payload: { operationUUID, variationUUID, updates } };
}

function deleteMainProductVariation({ operationUUID, variationUUID } = {}) {
    return { type: DELETE_MAIN_PRODUCT_VARIATION_INVOICE, payload: { operationUUID, variationUUID } };
}

function updateInvoiceOperation({ operationUUID, updates } = {}) {
    return { type: UPDATE_INVOICE_OPERATION, payload: { operationUUID, updates } };
}

const getInvoicesAnalytics = () => (dispatch) => {
    dispatch({ type: GET_INVOICES_ANALYTICS_REQUEST });
    invoiceService
        .getInvoicesAnalytics()
        .then(({ data }) => {
            dispatch({ type: GET_INVOICES_ANALYTICS_SUCCESS, payload: data });
        })
        .catch(() => {
            dispatch({ type: GET_INVOICES_ANALYTICS_FAILURE });
        });
};

function updateAdvancePaymentVatRate({ vatRate }) {
    return {
        type: UPDATE_ADVANCE_PAYMENT_VAT_RATE,
        payload: { vatRate },
    };
}

function generateMovingCertificate({ invoiceUUID, data, onSuccess }) {
    return (dispatch) => {
        dispatch(formActions.startRequesting());
        invoiceService
            .generateMovingCertificate(invoiceUUID, data)
            .then(() => {
                onSuccess();
                dispatch(dialogsActions.showSuccessDialog({ message: 'L’attestation à bien été généré.' }));
                dispatch(formActions.stopRequesting());
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function updateInvoice(invoiceUUID, updates) {
    return (dispatch) => {
        dispatch({ type: UPDATE_INVOICE_REQUEST });
        dispatch(formActions.startRequesting());

        invoiceService
            .updateInvoice(invoiceUUID, updates)
            .then(({ data: invoice }) => {
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: 'La facture a bien été acquittée' }));
                dispatch({ type: UPDATE_INVOICE_SUCCESS, payload: invoice });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                dispatch({ type: UPDATE_INVOICE_FAILURE });
                dispatch(formActions.stopRequesting());
            });
    };
}

function updateOperationTechnician({ operationUUID, technician }) {
    return {
        type: UPDATE_INVOICE_OPERATION_TECHNICIAN,
        payload: { operationUUID, technician },
    };
}

function rectifyInvoice(uuidInvoice) {
    return (dispatch) => {
        dispatch({ type: RECTIFY_INVOICE_REQUEST });
        dispatch(formActions.startRequesting());
        invoiceService
            .rectifyInvoice(uuidInvoice)
            .then(({ data: invoice }) => {
                dispatch({ type: RECTIFY_INVOICE_SUCCESS, payload: { invoice } });
                dispatch(formActions.stopRequesting());
                history.push(`/factures/${invoice.uuid}/edition`);
            })
            .catch(handleErrorResponse({ dispatch, errorType: RECTIFY_INVOICE_FAILURE }));
    };
}

function acquitInvoice(uuidInvoice) {
    return (dispatch) => {
        dispatch({ type: ACQUIT_INVOICE_REQUEST });
        dispatch(formActions.startRequesting());
        invoiceService
            .acquitInvoice(uuidInvoice)
            .then(({ data: invoice }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: ACQUIT_INVOICE_SUCCESS, payload: { invoice } });
                history.push(`/factures/${invoice.uuid}/apercu`);
            })
            .catch(handleErrorResponse({ dispatch, errorType: ACQUIT_INVOICE_FAILURE }));
    };
}

function checkInvoiceDocumentsGeneration({ invoiceUUID, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: CHECK_INVOICE_DOCUMENTS_GENERATION_REQUEST });
        dispatch(formActions.startRequesting());

        invoiceService
            .getInvoice(invoiceUUID)
            .then(({ data: invoice }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: CHECK_INVOICE_DOCUMENTS_GENERATION_SUCCESS, payload: { invoice } });
                if (!asyncJobHelper.isEveryAsyncJobDone(invoice)) {
                    dispatch(dialogsActions.showSuccessDialog({ message: ASYNC_JOBS_STILL_IN_PROGRESS, shouldShowIcon: false }));
                    return;
                }
                if (onSuccess) onSuccess(invoice);
            })
            .catch(handleErrorResponse({ dispatch, errorType: CHECK_INVOICE_DOCUMENTS_GENERATION_FAILURE, showErrorDialog: false }));
    };
}

function updateInvoiceProduct({ productUUID, updates, onSuccess, showLoading = true }) {
    return (dispatch) => {
        dispatch({ type: UPDATE_INVOICE_PRODUCT_REQUEST });
        if (showLoading) dispatch(formActions.startRequesting());

        return productCatalogueService
            .updateProduct(productUUID, updates)
            .then(({ data: product }) => {
                if (onSuccess) onSuccess();
                dispatch({ type: UPDATE_INVOICE_PRODUCT_SUCCESS, payload: { product } });
                if (showLoading) dispatch(formActions.stopRequesting());
            })
            .catch(handleErrorResponse({ dispatch, errorType: UPDATE_INVOICE_PRODUCT_FAILURE, showErrorDialog: showLoading }));
    };
}

export default {
    createInvoice,
    getInvoice,
    saveInvoice,
    updateOperationInstallationDate,
    updateOperationInstallationEndDate,
    updateOperationProductQuantity,
    updateInvoiceIssueDate,
    getInvoices,
    deleteInvoice,
    cancelInvoice,
    updateOperationOtherProductQuantity,
    resetOtherProduct,
    updateMainProduct,
    updateLastOtherProduct,
    addOtherProduct,
    updateOtherProduct,
    deleteOtherProduct,
    updateInvoiceStatusToPaid,
    updateWasteManagement,
    sendInvoiceByEmail,
    addMainProductVariation,
    updateMainProductVariation,
    deleteMainProductVariation,
    updateInvoiceOperation,
    getInvoicesAnalytics,
    setTotalNetToOneEuro,
    updateAdvancePaymentVatRate,
    generateMovingCertificate,
    updateOperationTechnician,
    updateInvoice,
    updateNonEnergyRenovationOperationMainProduct,
    rectifyInvoice,
    acquitInvoice,
    updateMar,
    checkInvoiceDocumentsGeneration,
    updateOperationProduct,
    updateInvoiceProduct,
};
