import { useCallback, useEffect, useState } from 'react';
import { getFeuille, getParcelle } from '../../../store/services/cadastre.service';
import { executeAnyTypeOfRequest } from '../../../store/actions/ui/form';
import { useDispatch } from 'react-redux';

export const useParcelleCadastre = (codeInsee) => {
    const dispatch = useDispatch();
    const [parcelles, setParcelles] = useState(null);
    const [feuilles, setFeuilles] = useState(null);

    const getInseeParcelle = useCallback(
        async ({ code_insee, section, code_arr, code_com, code_dep, nom_com }, geometry) => {
            dispatch(
                executeAnyTypeOfRequest(
                    async () => getParcelle({ code_insee, section, code_arr, code_com, code_dep, nom_com }, geometry),
                    {onSuccess:(data) => setParcelles(data.features)}
                )
            );
        },
        [dispatch]
    );

    const getInseeFeuille = useCallback(
        async ({ codeInsee }) => {
            return dispatch(
                executeAnyTypeOfRequest(
                    async () => getFeuille({ codeInsee }),
                    {onSuccess:(data) => setFeuilles(data.features)}
                )
            );
        },
        [dispatch]
    );

    const onSelectFeuille = useCallback(
        ({ code_arr, code_com, code_dep, code_insee, com_abs, echelle, feuille, nom_com, section }, geometry) => {
            return getInseeParcelle({ code_insee, section, code_arr, code_com, code_dep, nom_com }, geometry);
        },
        [getInseeParcelle]
    );

    useEffect(() => {
        if (!codeInsee) return;
        getInseeFeuille({ codeInsee: codeInsee });
    }, [getInseeFeuille, codeInsee]);

    return { parcelles, feuilles, onSelectFeuille };
};
