import { isNonEmptyString, isValidValue } from '.';
import Cookies from 'js-cookie';

import tokenUtils from './token_utils';

// storage keys
export const TOKEN_KEY = 'token_key';
export const ORGANIZATION_UUID_KEY = 'ORGANIZATION_UUID_KEY';
export const RETURN_URL_KEY = 'return_url';

function isAuthenticate() {
    return isValidValue(getToken());
}

function setToken({ token } = {}) {
    Cookies.set('token', token);
}

function getToken() {
    const cookieToken = Cookies.get('token');
    if (isNonEmptyString(cookieToken)) {
        return cookieToken;
    }
    return undefined;
}

function isComplete() {
    return isValidValue(getOrganizationUUID());
}

function setOrganizationUUID({ organizationUUID } = {}) {
    return localStorage.setItem(ORGANIZATION_UUID_KEY, organizationUUID);
}

function getOrganizationUUID() {
    return localStorage.getItem(ORGANIZATION_UUID_KEY);
}

// Clear
function clearAll() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ORGANIZATION_UUID_KEY);
    Cookies.remove('token');
}

function getAccountId() {
    const token = getToken();
    if (!token) return null;

    const payload = tokenUtils.decodeToken(token);

    return payload.sub;
}

// Testing
function setOrganizationAsCompleted() {
    setOrganizationUUID({ organizationUUID: process.env.REACT_APP_ORGANIZATION_UUID });
}

function setAccountAsLoggedIn() {
    setToken({ token: process.env.REACT_APP_TOKEN });
}

/**
 * @param {string} returnUrl
 */
function setReturnUrl(returnUrl) {
    sessionStorage.setItem(RETURN_URL_KEY, returnUrl);
}

function removeReturnUrl() {
    sessionStorage.removeItem(RETURN_URL_KEY);
}

function getReturnUrl() {
    return sessionStorage.getItem(RETURN_URL_KEY);
}

export default {
    isAuthenticate,
    setToken,
    getToken,
    clearAll,
    getAccountId,
    isComplete,
    setOrganizationUUID,
    getOrganizationUUID,
    setOrganizationAsCompleted,
    setAccountAsLoggedIn,
    setReturnUrl,
    removeReturnUrl,
    getReturnUrl,
};
