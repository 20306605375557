export const DIFFERENT_PASSWORDS = 'Vos mots de passe ne sont pas identiques';

export const OBLIGATORY_CONFIRM_PASSWORD = 'Confirmation du mot de passe obligatoire';

export const UNAUTHORIZED_EMAIL = 'Adresse email non autorisée';
export const MISSING_EMAIL = 'Adresse email obligatoire';
export const INVALID_EMAIL = 'Adresse email non valide';

export const INVALID_ADDRESS = "Nous n'avons pas pu récupérer toutes les informations concernant l'adresse. Veuillez renseigner les informations manquantes.";

export const MUST_PROVIDE_AT_LEAT_ONE_PHONE_NUMBER = 'Vous devez renseigner au moins un numéro de téléphone';

export const INVALID_PHONE_NUMBER = 'Numéro de téléphone non valide';

export const TABLE_COLUMN_START_DATE = 'Date de début';
export const TABLE_COLUMN_END_DATE = 'Date de fin';

export const TOTAL_PRE_TAX_WITH_COMMERCIAL_DISCOUNT = 'Montant total HT après remise';

export const NOT_SPECIFIED = 'Non renseigné';
export const TABLE_CREATED_BY_TITLE = 'Utilisateurs';

export const YOUR_REQUEST_HAS_BEEN_RECEIVED = 'Votre demande a bien été prise en compte.';
export const YOUR_CHOICE_HAS_BEEN_RECEIVED = 'Votre choix a bien été pris en compte.';

export const ADVANCE_PAYMENT_AGREEMENT_MESSAGE =
    'Nous vous informons que vous avez complété l’ensemble des éléments requis pour votre dossier de rénovation énergétique.\n Vous pouvez dès à présent effectuer votre demande de paiement rapide. Dès que nos équipes auront validé votre dossier, nous vous communiquerons la date prévisionnelle de versement des fonds. Soyez assuré que nous mettons tout en œuvre pour traiter cette étape avec la plus grande diligence.';

export const BENEFICIARY_APPLICATION_ACCESS_EMAIL_SENT = "L'accès a bien été envoyé.";

export const ASYNC_JOBS_STILL_IN_PROGRESS = 'Traitement en cours <br/ >Veuillez cliquer à nouveau dans un instant afin que tous les documents soient prêts.';

export const TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_SENT = 'Le lien a bien été envoyé.';

export const SEND_DOCUMENT_TO_SIGN = 'Envoyer le document à signer';
export const UPLOAD_DOCUMENT = 'Ajouter le document';

export const ONE_EURO_BUTTON_DISABLED_MESSAGE =
    'La prime minimum à reverser ne peut pas être supérieure au montant des travaux. Veuillez augmenter le montant des travaux pour activer le bouton à 1 euro.';
