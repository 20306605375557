import { SizedImg } from './common';
import warningIcon from '../../../../assets/img/others/warning_information.svg';
import { MA_PRIME_RENOV_ALERT_TEST_ID } from '../../../../tests/utils/tests-ids';
import { Text, TextBold } from '@renolib/renolib-ui-kit';
import React from 'react';
import { CenterElementContainer } from './MaPrimeRenovAlertDialog';

export const BuyMoreFolders = () => {
    return (
        <>
            <div className='d-flex justify-content-center mb-1'>
                <SizedImg src={warningIcon} alt='' />
            </div>
            <CenterElementContainer data-testid={MA_PRIME_RENOV_ALERT_TEST_ID}>
                <TextBold>OUPS vous avez expiré votre nombre de dossiers ! </TextBold>
                <span className='mt-2'>
                    <Text.Label>{'Si vous souhaitez en facturer d’avantage ce mois-ci, nous vous proposons de payer par dossier ou de changer d’offre'}</Text.Label>
                </span>
            </CenterElementContainer>
        </>
    );
};
