import { getRandomArrayValue } from './array.helper';
import {
    customerTypes,
    operationHeatingTypes,
    renovationAddressBuildingSectors,
    quotationTechnicianTypes,
    operationInstallationFinishesTranslations,
    operationTypes,
    productCategoriesValues,
    photovoltaicPanelsSubCategories,
    quotationTypes,
} from './enums';
import {
    LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_FIOUL,
    LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_CHARBON_OU_GAZ,
    LABEL_HEATING_TYPE_AFTER_INSTALLATION,
    LABEL_HEATING_TYPE_BEFORE_INSTALLATION,
} from './operation-constants';
import operationSheetNames from './operation-sheet-names';
import { isNullishOrEmpty, isValidValue, roundNumberToTwoDecimal } from '.';
import { isOperationOfRenovationGlobal } from './renovation-global-helper';
import { energyRenovationWorks } from './enums';
import productHelper from './product-helper';

function formatOperationFieldLabel(label = '') {
    const cleanedLabel = label.replace(/\W$/, '').trim();

    return cleanedLabel.charAt(0).toUpperCase() + cleanedLabel.substring(1);
}

function hasOperationChanged(operation = {}, operationSheetName) {
    return operation.serviceId || operation.workId || (operation.operationSheetName && operation.operationSheetName !== operationSheetName);
}

function getOperationSheetNameLabel({ name, title } = {}) {
    if (!name || !title) return '';

    const nameParts = name.replace(/_/g, '-').split('V-');
    const operationName = nameParts[0];
    let version = nameParts[1];
    let formattedTitle = title;
    if (version.includes('-CDP')) {
        version = version.replace('-CDP', '');
        formattedTitle += ' (Coup de pouce)';
    }
    version = version.replace('-', '.');

    return `${operationName} (v. ${version}) - ${formattedTitle}`;
}

function doesOperationMatchAddressBuildingSector(operationSheetName, { buildingSector }) {
    if (!buildingSector) return true;
    if (operationSheetName.includes('BAT')) return buildingSector === renovationAddressBuildingSectors.TERTIARY;
    if (operationSheetName.includes('RES')) return true;
    return buildingSector === renovationAddressBuildingSectors.RESIDENTIAL;
}

function filterOutCdpOperationsForCustomerPM(operationSheetName, customer) {
    if (operationSheetName === 'BAR_TH_164V_A36_2_CDP') return operationSheetName;
    if (customer.customerType === customerTypes.CUSTOMER_PM && operationSheetName.includes('_CDP') && !operationSheetName.includes('BAR_TH_173')) {
        return;
    }
    return operationSheetName;
}

function getOperationSheetsOptions({ operationSheets, energyRenovationServices, energyRenovationWorks, address, customer, quotationType }) {
    const formattedOperationSheets = operationSheets
        .filter(({ name }) => doesOperationMatchAddressBuildingSector(name, address))
        .filter(({ name }) => filterOutCdpOperationsForCustomerPM(name, customer))
        .map((operationSheet) => ({
            label: operationSheet.formattedOperationSheetName,
            value: operationSheet,
        }));

    if (quotationType === quotationTypes.ENERGY_RENOVATION_QUOTATION) return formattedOperationSheets;

    formattedOperationSheets.unshift(...energyRenovationServices.map((service) => ({ label: service.serviceName, value: service })));
    formattedOperationSheets.unshift(...energyRenovationWorks.map((work) => ({ label: work.workName, value: work })));

    return formattedOperationSheets;
}

function getOperationSelectPlaceholder(operationSheets = [], operationSheetName) {
    const operationSheet = operationSheets.find(({ name }) => name === operationSheetName);
    if (!operationSheet) return '';

    return getOperationSheetNameLabel(operationSheet);
}

function getHeatingTypesOptions() {
    return [
        { label: 'Électricité', value: operationHeatingTypes.ELECTRICITE },
        { label: 'Combustible', value: operationHeatingTypes.COMBUSTIBLE },
    ];
}

function getRadioGroupYesOrNoOptions(options) {
    return [
        { label: 'Oui', value: true, disabled: options?.disabled },
        { label: 'Non', value: false, disabled: options?.disabled },
    ];
}

function getEquipementInstalleOptions() {
    return [
        { label: LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_CHARBON_OU_GAZ, value: LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_CHARBON_OU_GAZ },
        { label: LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_FIOUL, value: LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_FIOUL },
    ];
}

function getOperationSheetUnitLabel(operationSheetName) {
    const operationSheetUnits = {
        SQUARE_METER: [
            'BAR_EN_101V_A33_3_CDP',
            'BAR_EN_101V_A33_3',
            'BAR_EN_101V_A39_4_CDP',
            'BAR_EN_101V_A39_4',
            'BAR_EN_102V_A14_1',
            'BAR_EN_102V_A39_2',
            operationSheetNames.BAR_EN_103V_A36_4_CDP,
            operationSheetNames.BAR_EN_103V_A36_4,
            operationSheetNames.BAR_EN_103V_A39_5_CDP,
            operationSheetNames.BAR_EN_103V_A39_5,
            'BAR_EN_106V_A33_4',
            'BAR_EN_107V_A20_3',
            'BAR_EN_109V_A24_1',
            'BAT_EN_101V_A33_3',
            'BAT_EN_102V_A27_2',
            'BAT_EN_103V_A33_3',
            'BAT_EN_106V_A33_2',
            'BAT_EN_108V_A19_1',
            'BAT_EN_109V_A24_1',
            operationSheetNames.BAR_EN_101V_A54_5,
        ],
        METER: [operationSheetNames.BAR_TH_160V_A27_1, operationSheetNames.BAR_TH_160V_A39_2, 'BAT_TH_146V_A31_2', operationSheetNames.BAR_TH_160V_A54_4, operationSheetNames.BAT_TH_146],
        UNIT: [
            'BAR_EN_104V_A14_1',
            'BAR_TH_106V_A23_2',
            'BAR_TH_127V_A36_3',
            'BAR_TH_159V_A26_1_CDP',
            'BAR_TH_159V_A26_1',
            'BAR_TH_159V_A41_2_CDP',
            'BAR_TH_159V_A41_2',
            'BAR_TH_104V_A23_2_CDP',
            'BAR_TH_104V_A23_2',
            'BAR_TH_104V_A41_3_CDP',
            'BAR_TH_104V_A41_3',
            operationSheetNames.BAR_TH_101,
            operationSheetNames.BAR_TH_148,
            operationSheetNames.BAR_TH_129V_A27_3,
            operationSheetNames.BAR_TH_143V_A25_1,
            operationSheetNames.BAR_TH_143V_A25_1_CDP,
            operationSheetNames.BAR_TH_143V_A51_5,
            operationSheetNames.BAR_TH_143V_A51_5_CDP,
            operationSheetNames.BAR_TH_112V_A35_2,
            operationSheetNames.BAR_TH_112V_A35_2_CDP,
            operationSheetNames.BAR_TH_112V_A46_3,
            operationSheetNames.BAR_TH_112V_A46_3_CDP,
            operationSheetNames.BAR_TH_113V_A41_3,
            operationSheetNames.BAR_TH_113V_A41_3_CDP,
            operationSheetNames.BAR_TH_159V_A44_3,
            operationSheetNames.BAR_TH_159V_A44_3_CDP,
            operationSheetNames.BAR_TH_125V_A36_3,
            operationSheetNames.BAR_TH_125V_A40_4,
            operationSheetNames.BAR_TH_125V_A54_5,
            operationSheetNames.BAR_TH_127V_A40_4,
            operationSheetNames.BAR_TH_127V_A54_5,
            operationSheetNames.BAR_TH_124V_A35_3,
            operationSheetNames.RES_EC_104,
            operationSheetNames.BAR_TH_159V_A50_4,
            operationSheetNames.BAR_TH_159V_A50_4_CDP,
            energyRenovationWorks.PHOTOVOLTAIC_PANELS,
            operationSheetNames.BAR_TH_171V_A55_1,
            operationSheetNames.BAR_TH_171V_A55_1_CDP,
            operationSheetNames.BAR_TH_172V_A55_1,
            operationSheetNames.BAR_TH_172V_A55_1_CDP,
            operationSheetNames.BAR_EN_104V_A54_2,
            operationSheetNames.BAT_TH_104V_A32_2,
            operationSheetNames.BAT_EQ_133V_A17_1,
            operationSheetNames.BAR_TH_161V_A54_2,
            operationSheetNames.PORTE,
            operationSheetNames.BAR_TH_173V_A56_1,
            operationSheetNames.BAR_TH_173V_A56_1_CDP,
        ],
    };
    const matchingUnits = [];
    Object.keys(operationSheetUnits).forEach((unit) => {
        if (operationSheetUnits[unit].includes(operationSheetName)) {
            matchingUnits.push(unit);
        }
    });

    return matchingUnits.join('/');
}

function getSelectTechnicianPlaceholder(operation) {
    const { technicianType, technicianData } = operation.technician;
    if (technicianType === quotationTechnicianTypes.ORGANIZATION) return 'Technicien "Mon Organisation"';

    return technicianData ? `Technicien "${technicianData.name}"` : '';
}

function getSelectTechnicianOptions(subContractors, quotation = {}, operation) {
    if (!subContractors || isNullishOrEmpty(quotation?.operations) || isNullishOrEmpty(operation)) return [];

    if (isOperationOfRenovationGlobal(operation) && isNullishOrEmpty(operation?.operations)) return [];

    const options = [{ label: 'Technicien "Mon Organisation"', value: { technicianType: quotationTechnicianTypes.ORGANIZATION, technicianId: quotation.idOrganization } }];
    subContractors.forEach((subContractor) => {
        options.push({ label: `Technicien "${subContractor.name}"`, value: { technicianType: quotationTechnicianTypes.SUB_CONTRACTOR, technicianId: subContractor._id } });
    });

    return options;
}

function isOperationUnitValueDifferentOfMainProductQuantity(operationSheetName) {
    return [
        operationSheetNames.BAR_TH_104,
        operationSheetNames.BAR_TH_104_CDP,
        operationSheetNames.BAR_TH_159,
        operationSheetNames.BAR_TH_159_CDP,
        operationSheetNames.BAR_TH_106,
        operationSheetNames.BAR_TH_127V_A36_3,
        operationSheetNames.BAR_TH_129V_A27_3,
        operationSheetNames.BAR_TH_159V_A44_3,
        operationSheetNames.BAR_TH_159V_A44_3_CDP,
        operationSheetNames.BAR_TH_125V_A36_3,
        operationSheetNames.BAR_TH_125V_A40_4,
        operationSheetNames.BAR_TH_125V_A54_5,
        operationSheetNames.BAR_TH_127V_A40_4,
        operationSheetNames.BAR_TH_127V_A54_5,
        operationSheetNames.BAR_TH_124V_A35_3,
        operationSheetNames.BAR_TH_159V_A50_4,
        operationSheetNames.BAR_TH_159V_A50_4_CDP,
        operationSheetNames.BAR_TH_171V_A55_1,
        operationSheetNames.BAR_TH_171V_A55_1_CDP,
        operationSheetNames.BAR_TH_172V_A55_1,
        operationSheetNames.BAR_TH_172V_A55_1_CDP,
        operationSheetNames.BAR_TH_173V_A56_1,
        operationSheetNames.BAR_TH_173V_A56_1_CDP,
    ].includes(operationSheetName);
}

function isOperationTechnicianSubContractor(technician) {
    return technician.technicianType === quotationTechnicianTypes.SUB_CONTRACTOR;
}

function isOperationTechnicianRGECertificateValid(technician) {
    return technician.technicianData.rgeCertificates.length > 0;
}

const operationsThatNeedHeatingTypes = [
    operationSheetNames.BAR_EN_102V_A14_1,
    operationSheetNames.BAR_EN_102V_A39_2,
    operationSheetNames.BAR_EN_104,
    operationSheetNames.BAT_EN_101,
    operationSheetNames.BAT_EN_102,
    operationSheetNames.BAT_EN_103,
];

function isAnOperationThatNeedsHeatingTypes(operation) {
    return operationsThatNeedHeatingTypes.includes(operation.operationSheetName);
}

function isAnOperationWhereOperationDetailsHasHeatingTypeProperties(operation) {
    return operation.otherDetailsToSpecify.find(({ label }) => label === LABEL_HEATING_TYPE_AFTER_INSTALLATION || label === LABEL_HEATING_TYPE_BEFORE_INSTALLATION);
}
function getRandomInstallationFinishesDefaultValue() {
    const defaultValues = Object.values(operationInstallationFinishesTranslations).filter((value) => value !== operationInstallationFinishesTranslations.AUTRE);

    return getRandomArrayValue(defaultValues);
}

function isOperationUnitValueEqualToMainProductQuantity({ operationSheetName }) {
    return operationSheetName === operationSheetNames.BAT_EN_109;
}

function findOperationByOperationSheetName(operations, operationSheetName) {
    return operations.find((operation) => operation.operationSheetName === operationSheetName);
}

function isCDPOperation(operationSheetName) {
    return operationSheetName && operationSheetName.includes('_CDP');
}

function getValueLabel(productDetailToSpecifyValue) {
    const possibleValues = {
        true: 'Oui',
        false: 'Non',
    };

    return possibleValues[productDetailToSpecifyValue];
}

export const calculateOperationSavingCertificatePremiumForOneDollarNetPrice = (invoiceOrQuotation) => {
    const operationLength = invoiceOrQuotation.operations.length;
    return invoiceOrQuotation.operations.map((operation) => {
        const customerEnergySavingCertificatePremium = operation.customerEnergySavingCertificatePremium - (Math.abs(getTotalNetPrice(invoiceOrQuotation)) + 1) / operationLength;

        return {
            ...operation,
            customerEnergySavingCertificatePremium: Math.round((Number(customerEnergySavingCertificatePremium) + Number.EPSILON) * 1000) / 1000,
        };
    });
};

function calculateOperationPremiumForEachOperationForOneDollarNetPrice(invoiceOrQuotation) {
    let operations = invoiceOrQuotation.operations
        .map((operation) => ({
            uuid: operation.uuid,
            premium: operation.customerEnergySavingCertificatePremium,
            minimumPremium: operation.customerMinimumEnergySavingCertificatePremium,
            reduciblePremium: operation.customerEnergySavingCertificatePremium - operation.customerMinimumEnergySavingCertificatePremium || 0,
        }))
        .sort((prevOperation, nextOperation) => {
            return nextOperation.reduciblePremium - prevOperation.reduciblePremium;
        });
    const totalReduciblePremium = operations.reduce((accumulator, { reduciblePremium }) => accumulator + reduciblePremium, 0);
    let totalNetPrice = Math.abs(getTotalNetPrice(invoiceOrQuotation)) + 1;
    if (totalNetPrice > totalReduciblePremium) return false;

    operations = operations.map((operation) => {
        if (totalNetPrice === 0) return operation;

        let premium = operation.premium;
        if (operation.reduciblePremium >= totalNetPrice) {
            premium -= totalNetPrice;
            totalNetPrice = 0;
        } else {
            premium -= operation.reduciblePremium;
            totalNetPrice -= operation.reduciblePremium;
        }

        return { ...operation, premium: roundNumberToTwoDecimal(premium) };
    });

    return invoiceOrQuotation.operations.map((operation) => {
        const { premium } = operations.find(({ uuid }) => uuid === operation.uuid);

        return { ...operation, customerEnergySavingCertificatePremium: premium };
    });
}

function calculateTotalMinimumPremium(invoiceOrQuotation) {
    return roundNumberToTwoDecimal(
        invoiceOrQuotation.operations.reduce((accumulator, { customerMinimumEnergySavingCertificatePremium }) => accumulator + customerMinimumEnergySavingCertificatePremium, 0)
    );
}

function getTotalNetPrice({ totalNetPriceToPay, totalNetPrice }) {
    if (isValidValue(totalNetPriceToPay)) return totalNetPriceToPay;
    if (isValidValue(totalNetPrice)) return totalNetPrice;

    return 0;
}

function extractProductFinderAdditionalParams(operation) {
    const additionalParams = {};
    if ([operationSheetNames.BAR_TH_125V_A36_3, operationSheetNames.BAR_TH_125V_A40_4, operationSheetNames.BAR_TH_125V_A54_5].includes(operation.operationSheetName)) {
        additionalParams.installationType = operation.installationType;
    }

    return additionalParams;
}

function isEnergyRenovationService(operation) {
    return operation?.type === operationTypes.ENERGY_RENOVATION_SERVICE;
}

function isEnergyRenovationWork(operation) {
    return operation?.type === operationTypes.ENERGY_RENOVATION_WORK;
}

function isEnergyRenovationOperation(operation) {
    return operation?.type === operationTypes.ENERGY_RENOVATION_OPERATION;
}

function isEnergyRenovationWorkOrService(operation) {
    return isEnergyRenovationService(operation) || isEnergyRenovationWork(operation);
}

function isNonEnergyRenovationOperation(operation) {
    return operation?.type === operationTypes.NON_ENERGY_RENOVATION_OPERATION;
}

export const isRenovationGlobal = ({ operationSheetName }) => {
    if (!operationSheetName) return false;
    const [operationName] = operationSheetName.split('V_');
    return operationName === 'BAR_TH_164';
};

export const compareOperationSheetNameWithOperationName = ({ operationSheetName, operationName }) => {
    if (!operationSheetName) return false;
    const [operationNameFromOperationSheetName] = operationSheetName.split('V_');
    return operationNameFromOperationSheetName === operationName;
};

function getOperationSheetName(operation = {}, product = {}) {
    return productHelper.isPhotovoltaicPanelsCategory(product) ? productCategoriesValues.PHOTOVOLTAIC_PANELS : operation.operationSheetName;
}

function getPhotovoltaicPanelsSupplyWork(operations = []) {
    return operations.find((operation) => {
        if (!operation.blockValidity.valid || !operation.products.length) return false;
        const { product } = productHelper.getMainProduct(operation.products);
        return product.category === productCategoriesValues.PHOTOVOLTAIC_PANELS && product.subCategory === photovoltaicPanelsSubCategories.PHOTOVOLTAIC_PANELS_SUPPLY;
    });
}

function formatOperationSpecifiedDetailsForState(operation) {
    const { installationDetailsToSpecify = [], exclusionDetailsToSpecify = [], optionalDetails = [] } = operation;
    const formattedDetails = {};
    [...installationDetailsToSpecify, ...exclusionDetailsToSpecify, ...optionalDetails].forEach(({ label, value }) => {
        formattedDetails[label] = value;
    });

    return formattedDetails;
}

function isOndulatorTypes(subCategory) {
    return [photovoltaicPanelsSubCategories.MICRO_ONDULATOR].includes(subCategory);
}

const isOperationSheetNameEqualToShortName = ({ operationSheetName }, { shortName }) => {
    if (!operationSheetName) return false;
    const [rawOperationSheetName] = operationSheetName.split('V_');
    return rawOperationSheetName === shortName;
};
export const getOperationThroughtOperationShortName =
    (shortName) =>
    ({ operations = [] }) =>
        operations.find((operation) => isOperationSheetNameEqualToShortName(operation, { shortName }));

const isOperationLed = ({ operations = [] }) => {
    return operations?.find(({ operationSheetName }) => operationSheetName === 'RES_EC_104V_A14_1');
};

export const getBarTh173FirstFoundOperation = getOperationThroughtOperationShortName('BAR_TH_173');

function isHeatingCDPOperation({ operationSheetName }) {
    return [
        operationSheetNames.BAR_TH_112V_A46_3_CDP,
        operationSheetNames.BAR_TH_113V_A41_3_CDP,
        operationSheetNames.BAR_TH_143V_A51_5_CDP,
        operationSheetNames.BAR_TH_159V_A50_4_CDP,
        operationSheetNames.BAR_TH_171V_A55_1_CDP,
        operationSheetNames.BAR_TH_172V_A55_1_CDP,
    ].includes(operationSheetName);
}

export default {
    formatOperationFieldLabel,
    hasOperationChanged,
    getOperationSheetNameLabel,
    getOperationSheetsOptions,
    getOperationSelectPlaceholder,
    getHeatingTypesOptions,
    isAnOperationWhereOperationDetailsHasHeatingTypeProperties,
    getRadioGroupYesOrNoOptions,
    doesOperationMatchAddressBuildingSector,
    getOperationSheetUnitLabel,
    getSelectTechnicianPlaceholder,
    getSelectTechnicianOptions,
    isOperationUnitValueDifferentOfMainProductQuantity,
    isOperationTechnicianSubContractor,
    isOperationTechnicianRGECertificateValid,
    isAnOperationThatNeedsHeatingTypes,
    getRandomInstallationFinishesDefaultValue,
    getEquipementInstalleOptions,
    isOperationUnitValueEqualToMainProductQuantity,
    findOperationByOperationSheetName,
    isCDPOperation,
    getValueLabel,
    extractProductFinderAdditionalParams,
    isEnergyRenovationService,
    isEnergyRenovationWork,
    isEnergyRenovationWorkOrService,
    isNonEnergyRenovationOperation,
    compareOperationSheetNameWithOperationName,
    getOperationSheetName,
    isEnergyRenovationOperation,
    getPhotovoltaicPanelsSupplyWork,
    formatOperationSpecifiedDetailsForState,
    isOndulatorTypes,
    calculateOperationPremiumForEachOperationForOneDollarNetPrice,
    calculateTotalMinimumPremium,
    isOperationLed,
    getBarTh173FirstFoundOperation,
    isOperationSheetNameEqualToShortName,
    isHeatingCDPOperation,
};
