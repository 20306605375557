import React from 'react';
import { Text } from '@renolib/renolib-ui-kit';
import { v1 as uuidV1 } from 'uuid';
import entitiesUtils from './entities-utils';
import {
    customerTypes,
    quotationStatuses,
    quotationStatusesLabels,
    quotationStatusesActionButtonLabels,
    organizationInsuranceWarrantyAreas,
    applicationPages,
    organizationInsuranceWarrantyAreasTranslations,
    productTypes,
    productCategoriesValues as productCategories,
    mandatMprMixteEvents,
    dateUnits,
    quotationsAnnexes,
    uploadDocumentTypesTranslations,
    operationTypes,
    renovationAddressBuildingSectors,
    financingTypes,
    energyRenovationServices,
} from './enums';
import {
    QUOTATION_NOT_DELETABLE,
    QUOTATION_NOT_INVOICEABLE,
    QUOTATION_NOT_SENDABLE_AND_SIGNED,
    QUOTATION_NOT_SENDABLE_AND_INVOICED,
    QUOTATION_NOT_SENDABLE_NOR_PRINTABLE,
    QUOTATION_NOT_DELETABLE_AND_INVOICED,
    QUOTATION_NOT_INVOICEABLE_AND_INVOICED,
    QUOTATION_NOT_ADDABLE_OPERATIONS,
    QUOTATION_NOT_SENDABLE_BECAUSE_OF_EXPIRATION,
    QUOTATION_NOT_INVOICEABLE_BECAUSE_OF_ADVANCE_PAYMENT_INVOICE,
    QUOTATION_NOT_INVOICEABLE_BECAUSE_ADVANCE_PAYMENT_INVOICE_ALREADY_EXISTS,
    QUOTATION_NOT_INVOICEABLE_BECAUSE_CLASSIC_INVOICE_ALREADY_EXISTS,
    QUOTATION_NOT_INVOICEABLE_WITH_ADVANCE_PAYMENT_INVOICE,
    QUOTATION_NOT_ADDABLE_OPERATIONS_IF_RENOVATION_GLOBAL,
} from './messages';
import { isNonEmptyArray, isNonEmptyObject, isNullish, isNullishOrEmpty, isValidValue } from '.';
import history from './history';
import { formatQuotationContractSelectorName } from '../components/core/contracts/contract/amendments/amendment_modal/amendment-helper';
import dateUtils from './date_utils/moment/index';
import operationSheetNames from './operation-sheet-names';
import productFormHelper from './product-form-helper';
import operationHelper from './operation-helper';
import { doesTheQuotationContainAnOperationDampleur, getOperationOfRenovationGlobalFromOperations, isOperationOfRenovationGlobal } from './renovation-global-helper';
import { LABEL_EXPORT_QUOTATIONS_PREFIX } from './form_labels';
import { dateFormats } from './date_utils';

function formatCustomerName(customer, { emptyStringAsDefault = false, includeLastName = false, withGender = true, includeCustomerPMAddress = false } = {}) {
    const { customerType, customerData } = customer;

    if (!customer) return emptyStringAsDefault ? '' : 'Non renseigné';
    if (!customerData) return emptyStringAsDefault ? '' : 'Non renseigné';

    const { name } = customerData;
    const customerLabelStrategies = {
        [customerTypes.CUSTOMER_PP]: () => formatCustomerPPName(customerData, { includeLastName, withGender }),
        [customerTypes.CUSTOMER_PM]: () => (includeCustomerPMAddress ? `${name} - ${customer.customerData?.fullAddress}` : name),
    };
    const strategy = customerLabelStrategies[customerType];

    return strategy ? strategy() : '';
}

function formatCustomerPPName({ gender, fullName, lastName = '' }, option) {
    const name = option?.includeLastName ? lastName : fullName;

    if (option?.withGender && gender) return { MALE: 'M.', FEMALE: 'Mme' }[gender].concat(' ', name);

    return name;
}

function formatOperationSheetName(operationSheetName) {
    if (!operationSheetName) return;
    const nameParts = operationSheetName?.replace(/_/g, '-').split('V-');
    const operationName = nameParts[0];
    let version = nameParts[1];
    let formattedSuffix = '';
    if (version && version.includes('-CDP')) {
        version = version.replace('-CDP', '');
        formattedSuffix += ' - (Coup de pouce)';
    }
    version = version && version.replace('-', '.');

    return `${operationName} (v. ${version})${formattedSuffix}`;
}

function getOperationsNames(operations = []) {
    return operations.map((operation) => ({
        key: uuidV1(),
        name: formatOperationName(operation),
    }));
}

function formatOperationName({ type, operationSheetName, serviceName, workName }) {
    if (operationHelper.isEnergyRenovationService({ type })) return serviceName;
    if (operationHelper.isEnergyRenovationWork({ type })) return workName;
    if (operationHelper.isNonEnergyRenovationOperation({ type })) return '';

    return formatOperationSheetName(operationSheetName);
}

function getStatusLabel(status) {
    return quotationStatusesLabels[status];
}

function getActionButtonLabel(status) {
    return quotationStatusesActionButtonLabels[status];
}

function getQuotationAnnexes(quotation) {
    const { swornStatement, cadreContribution, vatCertificate, claimAssignment, socialLandlordSwornStatement, renovationPremium, attestations = [], jointOwnershipCertificate } = quotation;
    const annexes = [];

    if (swornStatement?.fileDownloadUrl) {
        swornStatement.name = quotationsAnnexes.SWORN_STATEMENT;
        annexes.push(swornStatement);
    }
    if (cadreContribution?.fileDownloadUrl) {
        cadreContribution.name = quotationsAnnexes.CADRE_CONTRIBUTION;
        annexes.push(cadreContribution);
    }
    if (vatCertificate?.fileDownloadUrl) {
        vatCertificate.name = quotationsAnnexes.TAX_NOTICE;
        annexes.push(vatCertificate);
    }
    if (claimAssignment) {
        claimAssignment.name = quotationsAnnexes.CLAIM_ASSIGNMENT;
        annexes.push(claimAssignment);
    }
    if (socialLandlordSwornStatement) {
        socialLandlordSwornStatement.name = quotationsAnnexes.SOCIAL_LANDLORD_ATTESTATION;
        annexes.push(socialLandlordSwornStatement);
    }
    if (renovationPremium?.mandateDownloadUrl) {
        renovationPremium.mandateDownloadUrl.name = quotationsAnnexes[renovationPremium?.representativeType] || '';
        annexes.push(renovationPremium.mandateDownloadUrl);
    }
    if (jointOwnershipCertificate?.fileDownloadUrl) {
        jointOwnershipCertificate.name = quotationsAnnexes.JOINT_OWNER_SHIP_CERTIFICATE;
        annexes.push(jointOwnershipCertificate);
    }

    if (attestations.length) {
        attestations.forEach((attestation) => {
            attestation.name = uploadDocumentTypesTranslations[attestation.type];
            annexes.push(attestation);
        });
    }

    return annexes;
}

function formatQuotationsForTable(quotations = []) {
    return quotations.map((quotation) => ({
        key: quotation.uuid,
        uuid: quotation.uuid,
        quotationNumber: quotation.quotationNumber,
        customer: formatCustomerName(quotation.customer, { withGender: false }),
        quotationIssueDate: quotation.quotationIssueDate,
        operations: getOperationsNames(quotation.operations),
        energySavingCertificatePremium: quotation.energySavingCertificatePremium,
        totalPriceToPay: quotation.totalPriceToPay,
        totalNetPriceToPay: quotation.totalNetPriceToPay,
        hasAdministrativeProblem: quotation.hasAdministrativeProblem,
        status: quotation.status,
        cadreContribution: quotation.cadreContribution,
        createdBy: quotation.createdBy,
        annexes: getQuotationAnnexes(quotation),
        refusalReason: quotation.refusalReason || 'Refusé par le client',
        premiums: { cee: quotation.customerEnergySavingCertificatePremium, mpr: !!quotation.renovationPremium?.representativeType && quotation.renovationPremium?.maPrimeRenovTotalAmount },
        quotation: {
            status: quotation.status,
            uuid: quotation.uuid,
            quotationExpirationDate: quotation.quotationExpirationDate,
            operations: quotation.operations,
            canCreateAdvancePaymentInvoice: quotation.canCreateAdvancePaymentInvoice,
            canCreateClassicInvoice: quotation.canCreateClassicInvoice,
            advancePaymentInvoiceAlreadyExists: quotation.advancePaymentInvoiceAlreadyExists,
            classicInvoiceAlreadyExists: quotation.classicInvoiceAlreadyExists,
            advancePayments: quotation.advancePayments,
        },
    }));
}

function formatQuotationIssueDate(quotationIssueDate) {
    return entitiesUtils.formatDate(quotationIssueDate);
}
function formatQuotationPrimesAmount(premiums = {}) {
    const { cee = 0, mpr = 0 } = premiums;

    return (
        <div className='d-flex flex-column justify-content-between'>
            {cee > 0 && (
                <Text.Label className='ws-nowrap'>
                    <i className='fa-regular fa-circle-bolt color-blue-jeans fs-16 my-1 mr-1'></i>
                    {window.innerWidth > 834 && <span className={`color-blue-jeans fw-600 mr-1`}> CEE</span>}
                    {entitiesUtils.formatPrice(cee)}
                </Text.Label>
            )}
            {mpr > 0 && (
                <Text.Label className='ws-nowrap'>
                    <i className='fa-regular fa-leaf color-sea-green fs-16 my-1 mr-1'></i>
                    {window.innerWidth > 834 && <span className={`color-sea-green fw-600 mr-1`}> MPR</span>}
                    {entitiesUtils.formatPrice(mpr)}
                </Text.Label>
            )}
        </div>
    );
}

function filterQuotationsByStatus(quotations = [], status) {
    return quotations.filter((quotation) => quotation.status === status);
}

function filterDraftQuotations(quotations) {
    return filterQuotationsByStatus(quotations, quotationStatuses.DRAFT);
}

function filterSentQuotations(quotations) {
    return filterQuotationsByStatus(quotations, quotationStatuses.SENT);
}

function filterSignedQuotations(quotations) {
    return filterQuotationsByStatus(quotations, quotationStatuses.SIGNED);
}

function changeEditionPathNameToPreviewPathname(pathname) {
    return pathname.replace('edition', 'apercu');
}

function getCustomerNumber(customer) {
    return customer.customerNumber || 'Non sélectionné';
}

function getWarrantyAreaLabel(warrantyArea) {
    const warrantyAreaPrefix = warrantyArea === organizationInsuranceWarrantyAreas.DOM_TOM ? 'les ' : 'la ';

    return warrantyAreaPrefix.concat(organizationInsuranceWarrantyAreasTranslations[warrantyArea]);
}

function isSameCustomer(customer, { customerId, customerType } = {}) {
    return customer.customerId === customerId && customer.customerType === customerType;
}

function isSameCustomerPMAddress(customer = {}, { addressUUID } = {}) {
    return isCustomerPM(customer.customerType) && customer.customerData?.addressUUID === addressUUID;
}

function getRenovationAddressesOptions(renovationAddresses = []) {
    return renovationAddresses.map((renovationAddress) => ({
        label: entitiesUtils.getFullAddressText(renovationAddress),
        value: { renovationAddressId: renovationAddress._id, householdIncomeBonification: renovationAddress.householdIncomeBonification, buildingSector: renovationAddress.buildingSector },
    }));
}

function isSameRenovationAddress(address, { renovationAddressId }) {
    return address.renovationAddressId === renovationAddressId;
}

function isSameBuildingAgeValue(address, { buildingAge }) {
    return address.buildingAge === buildingAge;
}

function getSelectContractPlaceholder({ blockValidity, reference, delegateeName, contractAmendments }) {
    if (!blockValidity.validated) return 'Aucun contrat';
    if ([blockValidity.valid, reference, delegateeName].some((value) => !value)) return 'Aucun contrat';

    return formatQuotationContractSelectorName({ reference, delegateeName, contractAmendments });
}

function getSelectContractOptions(contractDocs) {
    if (!contractDocs) return [];
    const { docs: contracts = [] } = contractDocs;

    const mappedContracts = contracts.map((contract) => ({
        label: formatSelectContractOption(contract),
        value: contract._id,
    }));
    mappedContracts.unshift({ label: 'Aucun contrat', value: '' });

    return mappedContracts;
}

function formatSelectContractOption(contract) {
    return formatQuotationContractSelectorName(contract);
}

function isSameContract(contract, { contractId }) {
    return contract.blockValidity.valid && contract.contractId === contractId;
}

function isQuotationValid(quotation) {
    const { customer, address, contract, operations } = quotation;
    let valid = customer.blockValidity.valid && address.blockValidity.valid;
    if (contract.blockValidity.validated) valid = contract.blockValidity.valid;
    operations.forEach((operation) => {
        valid = valid && operation.blockValidity.valid;

        const { products } = operation;
        products.forEach((product) => {
            valid = valid && product.blockValidity.valid;
        });
    });

    return valid;
}

function hasAFileDownloadUrl(quotation) {
    return quotation.fileDownloadUrl;
}

function getTotalPriceWithoutTax(quotation) {
    const { pricesPerVatRate = [] } = quotation;
    return pricesPerVatRate.reduce((accumulator, { totalPreTax }) => accumulator + totalPreTax, 0);
}

function getVatRates(quotation) {
    const { pricesPerVatRate = [] } = quotation;
    return pricesPerVatRate.map(({ vatRate, totalTax = 0 }) => ({
        key: uuidV1(),
        label: `TVA à ${vatRate * 100}%`,
        value: totalTax,
    }));
}

function isCustomerPM(customerType) {
    return customerType === customerTypes.CUSTOMER_PM;
}

function isCustomerPP(customerType) {
    return customerType === customerTypes.CUSTOMER_PP;
}

function isCommercialDiscountPercentageDisabled(quotation) {
    return quotation.totalPriceWithoutTax === 0 && quotation.commercialDiscount === 0;
}

function canShowCustomerEnergySavingCertificatePremium(quotation) {
    const { contract, energySavingCertificatePremium } = quotation;

    return contract.blockValidity.validated && contract.blockValidity.valid && energySavingCertificatePremium > 0;
}

function canShowCustomerEnergySavingCertificatePremiumForOperation(operation) {
    const { operationVolumesAndPremium } = operation;

    return operationHelper.isEnergyRenovationOperation(operation) && isNonEmptyObject(operationVolumesAndPremium) && operationVolumesAndPremium.premium > 0;
}

function getCustomerEnergySavingCertificatePremiumLabel(operation, quotation = {}) {
    const customerMinimumEnergySavingCertificatePremium = entitiesUtils.formatPrice(operation.customerMinimumEnergySavingCertificatePremium);

    if (operationHelper.isCDPOperation(operation.operationSheetName) && !operation?.operationVolumesAndPremium?.coupDePouce) {
        return `Vous devez reverser au minimum ${customerMinimumEnergySavingCertificatePremium}.`;
    }

    if (quotation?.contract?.customerMinimumEnergySavingCertificatePremiumPercentage === 100 || operation?.operationVolumesAndPremium?.coupDePouce) {
        return `Vous devez reverser ${customerMinimumEnergySavingCertificatePremium}.`;
    }

    return `Vous pouvez reverser entre ${customerMinimumEnergySavingCertificatePremium} et ${entitiesUtils.formatPrice(operation.operationVolumesAndPremium.premium)}.`;
}

function canShowTotalVolume(quotation) {
    return getOperationsTotalVolume(quotation) > 0;
}

function getOperationsTotalVolume(target) {
    const { operations = [] } = target;

    return operations
        .filter((op) => op.compliant)
        .reduce((accumulator, operation) => {
            const { operationVolumesAndPremium = {} } = operation;
            return accumulator + operationVolumesAndPremium.volumeTotal || 0;
        }, 0);
}

function getFormattedQuotationTotalVolume(quotation) {
    return entitiesUtils.formatVolume(getOperationsTotalVolume(quotation));
}

function canShowEnergySavingCertificatePremium(quotation) {
    return quotation.energySavingCertificatePremium > 0;
}

const isQuotationExpirationDateValid = ({ quotationExpirationDate }) => quotationExpirationDate && dateUtils.isSameOrAfter(quotationExpirationDate);

function getQuotationSendButtonMessage(quotation) {
    if (!isQuotationExpirationDateValid(quotation)) return QUOTATION_NOT_SENDABLE_BECAUSE_OF_EXPIRATION;

    return isQuotationSendable(quotation)
        ? ''
        : isQuotationInvoiced(quotation)
        ? QUOTATION_NOT_SENDABLE_AND_INVOICED
        : isQuotationSigned(quotation)
        ? QUOTATION_NOT_SENDABLE_AND_SIGNED
        : QUOTATION_NOT_SENDABLE_NOR_PRINTABLE;
}

function isQuotationSendable(quotation) {
    return [quotationStatuses.COMPLETED, quotationStatuses.SENT].includes(quotation.status) && isQuotationExpirationDateValid(quotation) && allProductsOfQuotationAreConfirmed(quotation);
}

function isQuotationSigned(quotation) {
    return [quotationStatuses.SIGNED, quotationStatuses.SIGNED_AND_FINALIZED_RELATED_INVOICE].includes(quotation.status);
}

function getQuotationPrintButtonMessage(quotation) {
    return isQuotationPrintable(quotation) ? '' : QUOTATION_NOT_SENDABLE_NOR_PRINTABLE;
}

function isQuotationPrintable(quotation) {
    return !!quotation.fileDownloadUrl && quotation.status !== quotationStatuses.DRAFT && allProductsOfQuotationAreConfirmed(quotation);
}

function canShowBackToEditionNotification(quotation = {}) {
    return [quotationStatuses.DRAFT, quotationStatuses.COMPLETED].includes(quotation.status);
}

function getQuotationInvoiceButtonMessage(quotation = {}) {
    const { canCreateClassicInvoice, advancePaymentInvoiceAlreadyExists, classicInvoiceAlreadyExists } = quotation;

    if (classicInvoiceAlreadyExists && quotation.status !== quotationStatuses.SIGNED_AND_FINALIZED_RELATED_INVOICE) return QUOTATION_NOT_INVOICEABLE_BECAUSE_CLASSIC_INVOICE_ALREADY_EXISTS;
    if (classicInvoiceAlreadyExists && quotation.status === quotationStatuses.SIGNED_AND_FINALIZED_RELATED_INVOICE) return QUOTATION_NOT_INVOICEABLE_AND_INVOICED;
    if (advancePaymentInvoiceAlreadyExists && !canCreateClassicInvoice) return QUOTATION_NOT_INVOICEABLE_BECAUSE_OF_ADVANCE_PAYMENT_INVOICE;

    return isQuotationInvoiceable(quotation) ? '' : isQuotationInvoiced(quotation) ? QUOTATION_NOT_INVOICEABLE_AND_INVOICED : QUOTATION_NOT_INVOICEABLE;
}

function getQuotationAdvancePaymentInvoiceButtonMessage(quotation = {}) {
    const { canCreateAdvancePaymentInvoice, advancePaymentInvoiceAlreadyExists, classicInvoiceAlreadyExists } = quotation;

    if (advancePaymentInvoiceAlreadyExists) return QUOTATION_NOT_INVOICEABLE_BECAUSE_ADVANCE_PAYMENT_INVOICE_ALREADY_EXISTS;
    if (advancePaymentInvoiceAlreadyExists && !canCreateAdvancePaymentInvoice && classicInvoiceAlreadyExists) return QUOTATION_NOT_INVOICEABLE_WITH_ADVANCE_PAYMENT_INVOICE;

    return isQuotationInvoiceable(quotation) ? '' : isQuotationInvoiced(quotation) ? QUOTATION_NOT_INVOICEABLE_AND_INVOICED : QUOTATION_NOT_INVOICEABLE;
}

function getQuotationDeleteButtonMessage(quotation = {}) {
    return isQuotationDeletable(quotation) ? '' : isQuotationInvoiced(quotation) ? QUOTATION_NOT_DELETABLE_AND_INVOICED : QUOTATION_NOT_DELETABLE;
}

function isQuotationInvoiceable(quotation = {}) {
    return (quotation.status === quotationStatuses.SIGNED && quotation.canCreateClassicInvoice) || isQuotationInvoiceableForAnAdvancePayment(quotation);
}

function isQuotationInvoiceableForAnAdvancePayment(quotation = {}) {
    return quotation.status === quotationStatuses.SIGNED && quotation.canCreateAdvancePaymentInvoice;
}

function isQuotationRefusable({ status }) {
    return [quotationStatuses.DRAFT, quotationStatuses.COMPLETED, quotationStatuses.SENT].includes(status);
}

function isQuotationDeletable(quotation = {}) {
    return [quotationStatuses.DRAFT, quotationStatuses.COMPLETED, quotationStatuses.EXPIRED, quotationStatuses.REFUSED, quotationStatuses.SENT].includes(quotation.status);
}

function isQuotationSent(quotation = {}) {
    return quotation.status === quotationStatuses.SENT;
}

function isQuotationInvoiced(quotation = {}) {
    return quotation.status === quotationStatuses.SIGNED_AND_FINALIZED_RELATED_INVOICE;
}

function getSelectOperationTechnicianMessage(quotationOperation) {
    const { valid, compliant, errorMessages } = quotationOperation.technician.blockValidity;

    return valid && compliant ? '' : errorMessages.length > 0 ? errorMessages[0] : '';
}

function doesQuotationHaveAtLeatOneCompliantOperation(quotation) {
    const { operations = [] } = quotation;

    return doesOperationsIncludeAtLeastOneCompliantOnes(operations);
}

function doesOperationsIncludeAtLeastOneCompliantOnes(operations) {
    return operations.reduce((atLeastOneOperationIsCompliant, { compliant = false }) => {
        return atLeastOneOperationIsCompliant || compliant;
    }, false);
}

function canShowTotalPreTaxWithCommercialDiscountField(quotation) {
    return quotation.commercialDiscount > 0;
}

function getTotalPreTaxWithCommercialDiscount(quotation) {
    return quotation.totalPriceWithoutTax - quotation.commercialDiscount;
}

function handleOpenQuotation(quotation) {
    const { status } = quotation;
    const openQuotationStrategies = {
        [quotationStatuses.DRAFT]: handleDraftQuotation,
        [quotationStatuses.COMPLETED]: handleCompleteQuotation,
        [quotationStatuses.SENT]: handleConsultQuotation,
        [quotationStatuses.SIGNED]: handleConsultQuotation,
        [quotationStatuses.SIGNED_AND_FINALIZED_RELATED_INVOICE]: handleConsultQuotation,
        [quotationStatuses.EXPIRED]: handleCompleteQuotation,
        [quotationStatuses.REFUSED]: handleConsultQuotation,
    };
    openQuotationStrategies[status](quotation);
}

function handleCompleteQuotation({ uuid }) {
    history.push(`/devis/${uuid}/edition`);
}

function handleDraftQuotation(quotation) {
    /*
    const filledPercentage = getFilledPercentageForCustomerAndAddressStepper(quotation);
    if (filledPercentage >= 100) {
        history.push(`/devis/${uuid}/edition`);
    } else {
        history.push(`/devis/${uuid}/edition/residential_information`);
    }*/
    const { uuid } = quotation;
    history.push(`/devis/${uuid}/edition`);
}

function handleConsultQuotation({ uuid }) {
    history.push(`/devis/${uuid}/apercu`);
}

function canShowCustomerColumn(targetPage) {
    return targetPage !== applicationPages.CUSTOMER;
}

function canShowTableMenuActions(targetPage) {
    return targetPage === applicationPages.QUOTATIONS;
}

function filterQuotationsWithVatCertificateAndFormatForTable(quotations = []) {
    return quotations
        .filter(({ vatCertificate }) => isValidValue(vatCertificate?.fileDownloadUrl))
        .map((quotation) => ({
            key: quotation.uuid,
            uuid: quotation.uuid,
            quotationNumber: quotation.quotationNumber,
            customer: formatCustomerName(quotation.customer),
            quotationIssueDate: quotation.quotationIssueDate,
            operations: getOperationsNames(quotation.operations),
            vatCertificate: quotation.vatCertificate,
        }));
}

function getOperationSheetNames(operations) {
    if (operations) return operations.map((operation) => operation.operationSheetName);
}

function isAddOperationButtonDisabled({ operations }) {
    const hasARenovationGlobalOperation = !!getOperationOfRenovationGlobalFromOperations({ operations });
    if (hasARenovationGlobalOperation) return true;

    return operations.some((operation) => {
        if (operation?.serviceId && operation.serviceId === energyRenovationServices.ENERGETIC_AUDIT) return !operation.compliant;

        if ([operationTypes.NON_ENERGY_RENOVATION_OPERATION, operationTypes.ENERGY_RENOVATION_WORK].includes(operation.type)) {
            const { products = [] } = operation;

            return !products.every((operationProduct) => operationProduct?.blockValidity?.valid);
        }

        return !operation.operationSheetName;
    });
}

function getAddNewOperationToolTipMessage(quotation) {
    const hasARenovationGlobalOperation = !!getOperationOfRenovationGlobalFromOperations(quotation);
    if (hasARenovationGlobalOperation) return QUOTATION_NOT_ADDABLE_OPERATIONS_IF_RENOVATION_GLOBAL;
    return isAddOperationButtonDisabled(quotation) ? QUOTATION_NOT_ADDABLE_OPERATIONS : '';
}

function extractSolarFactorCalculationNote(quotation) {
    if (isNonEmptyObject(quotation)) {
        const roofMainProduct = extractRoofMainProduct(quotation);

        return roofMainProduct?.solarFactorCalculationNote || {};
    }

    return {};
}

function extractRoofMainProduct(quotation) {
    const quotationProduct = quotation.operations
        .filter(({ operationSheetName }) => [operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName))
        .flatMap(({ products }) => products)
        .find(({ product }) => product && product.type === productTypes.MAIN_PRODUCT && product.category === productCategories.TOITURE);

    return quotationProduct && quotationProduct.product;
}

const isWasteManagementValid = (quotation) => quotation?.wasteManagement?.blockValidity?.valid;

function isSelectContractEnabled(quotation) {
    const { operations = [] } = quotation;

    return (
        areAllOperationsValid(operations) &&
        doesOperationsIncludeAtLeastOneCompliantOnes(operations) &&
        isWasteManagementValid(quotation) &&
        allProductsOfQuotationAreConfirmed({ operations }) &&
        !isOnlyEnergyRenovationWorkOrServiceDocument(quotation) &&
        !doesTheQuotationContainAnOperationDampleur(quotation)
    );
}

function areAllOperationsValid(operations = []) {
    return operations?.length > 0
        ? operations.reduce((allOperationsValid, { renovationGlobal, operationSheetName, products = [], blockValidity, ...operation }) => {
              if (operation?.serviceId && operation.serviceId === energyRenovationServices.ENERGETIC_AUDIT) {
                  return blockValidity.valid;
              }
              if (isOperationOfRenovationGlobal({ operationSheetName })) {
                  return areAllOperationsValid(renovationGlobal?.operations);
              }
              return allOperationsValid && blockValidity.valid && areAllOperationProductsValid(products);
          }, true)
        : false;
}

function areAllOperationProductsValid(operationProducts = []) {
    return operationProducts.length > 0
        ? operationProducts.reduce((allProductsValid, { blockValidity }) => {
              return allProductsValid && blockValidity.valid;
          }, true)
        : false;
}

function isQuotationExpired(quotation) {
    return isNonEmptyObject(quotation) && quotation.status === quotationStatuses.EXPIRED && isValidValue(quotation.expiredAt);
}

const areAllProductsValid = (products) => products.every(({ product }) => productFormHelper.isProductWithValidStatus(product));

const checkAllProductsValidityInARenovationGlobalOperation = ({ renovationGlobal }) => {
    const { operations = [] } = renovationGlobal;
    return operations.every(({ products }) => areAllProductsValid(products));
};

function allProductsOfQuotationAreConfirmed(quotation = {}) {
    const { operations = [] } = quotation;
    return operations.every((operation) => {
        if (isOperationOfRenovationGlobal(operation)) {
            return checkAllProductsValidityInARenovationGlobalOperation(operation);
        }
        if (operation?.serviceId && operation.serviceId === energyRenovationServices.ENERGETIC_AUDIT) {
            return operation.blockValidity.valid;
        }
        const { products = [] } = operation;
        return areAllProductsValid(products);
    });
}

function findHistoryRecordsRelatedToMaPrimeRenovOption(historyRecords) {
    if (!historyRecords) return [];
    return historyRecords.filter(
        (historyRecord) =>
            historyRecord.eventName === mandatMprMixteEvents.ORGANIZATION_MANDAT_MPR_MIXTE_DEACTIVATED || historyRecord.eventName === mandatMprMixteEvents.ORGANIZATION_MANDAT_MPR_MIXTE_ACTIVATED
    );
}

function findLastDateOfMaPrimeRenovOption(historyRecords = [], eventName) {
    return historyRecords
        .filter((historyRecord) => historyRecord.eventName === eventName)
        .sort((prevHistoryRecord, nextHistoryRecord) => new Date(prevHistoryRecord.createdAt) - new Date(nextHistoryRecord.createdAt))
        .pop()?.createdAt;
}

function isCreatedBeforeDeactivation(quotation, organization) {
    return dateUtils.isSameOrBefore(
        quotation?.createdAt,
        findLastDateOfMaPrimeRenovOption(organization?.historyRecords, mandatMprMixteEvents.ORGANIZATION_MANDAT_MPR_MIXTE_DEACTIVATED),
        dateUnits.MILLISECONDS
    );
}

function isCreatedAfterActivation(quotation, organization) {
    return dateUtils.isSameOrAfter(
        quotation?.createdAt,
        findLastDateOfMaPrimeRenovOption(organization?.historyRecords, mandatMprMixteEvents.ORGANIZATION_MANDAT_MPR_MIXTE_ACTIVATED),
        dateUnits.MILLISECONDS
    );
}

function canDisplayMixteMandatCheckbox(active = false, quotation, organization) {
    const lastHistoryRecords = findHistoryRecordsRelatedToMaPrimeRenovOption(organization?.historyRecords);
    if (lastHistoryRecords.length === 0) return active;
    return (active && isCreatedAfterActivation(quotation, organization)) || (!active && isCreatedBeforeDeactivation(quotation, organization));
}

function canShowJoinProductCheckBox({ operations = [] }) {
    let canShow = false;
    operations.forEach((operation) => {
        if (operation.products)
            operation.products.forEach((product) => {
                if (product?.product?.technicalSheetUrl) canShow = true;
            });
    });
    return canShow;
}

function extractMainProduct(quotation, selectedOperationsSheetNames = []) {
    const operation = quotation.operations.find(({ operationSheetName }) => selectedOperationsSheetNames.includes(operationSheetName));

    return isNonEmptyObject(operation) && isNonEmptyArray(operation.products) && operation.products.find(({ product }) => product?.type === productTypes.MAIN_PRODUCT);
}

function isOnlyEnergyRenovationWorkOrServiceDocument({ operations = [] } = {}) {
    return operations.every((operation) => operationHelper.isEnergyRenovationWorkOrService(operation));
}

function isEnergyRenovationWorkDocument({ operations = [] } = {}) {
    return operations.some((operation) => operationHelper.isEnergyRenovationWork(operation));
}

function isEnergyRenovationServiceDocument({ operations = [] } = {}) {
    return operations.some((operation) => operationHelper.isEnergyRenovationService(operation));
}

function extractNonEnergyRenovationOperationMainProduct({ products = [] }) {
    return products.find((quotationProduct) => quotationProduct?.product?.type === productTypes.NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT);
}

function isNonEnergyRenovationDocument({ operations = [] } = {}) {
    return operations.some(({ type }) => type === operationTypes.NON_ENERGY_RENOVATION_OPERATION);
}

function isEnergyRenovationDocument({ operations = [] } = {}) {
    return operations.some(({ type }) => type === operationTypes.ENERGY_RENOVATION_OPERATION);
}

function isPremiumApplicableForQuotation({ operations = [] } = {}) {
    return isEnergyRenovationDocument({ operations }) || !isOnlyEnergyRenovationWorkOrServiceDocument({ operations }) || !isNonEnergyRenovationDocument({ operations });
}

function isARenovationAddressWithoutOccupant(address) {
    if (address.buildingSector !== renovationAddressBuildingSectors.RESIDENTIAL) return false;
    return !address?.householdIncomeBonification?.housings?.length > 0;
}

function canCreateAQuotation(organization) {
    if (isNullish(organization) || isNullishOrEmpty(organization.organizationInsurances)) return false;

    const validInsurances = organization.organizationInsurances.filter((insurance) => insurance.status === 'CONFIRMED');
    const stillActiveInsurances = validInsurances.filter((insurance) => dateUtils.isSameOrAfter(insurance.endDate, new Date(), dateUnits.DAYS));
    return stillActiveInsurances.length > 0;
}

export const doesTheEntityHasARenovationGlobalOrARenovationPremium = (entity) => {
    return (entity?.renovationPremium?.representativeType && entity?.renovationPremium?.maPrimeRenovTotalAmount > 0) || getOperationOfRenovationGlobalFromOperations(entity);
};

function formatCreditFinancing(creditFinancing) {
    return {
        years: Number(creditFinancing.annee),
        months: Number(creditFinancing.mois),
        debitRate: Number(creditFinancing.taux_debiteur),
        monthlyAmount: Number(creditFinancing.mens_n),
        insuranceMonthlyAmount: Number(creditFinancing.ass_mens),
        totalMonthlyAmount: Number(creditFinancing.mensAC_n),
        interestRate: Number(creditFinancing.TAEG_HA),
        insuranceInterestRate: Number(creditFinancing.TAEA),
        totalInterestRate: Number(creditFinancing.TAEG_AC),
        totalInterestAmount: Number(creditFinancing.int),
        totalInsuranceAmount: Number(creditFinancing.ass_sum),
        totalAmount: Number(creditFinancing.montant_n),
        totalPremium: Number(creditFinancing.AIDES),
        totalDueAmount: Number(creditFinancing.total_du),
    };
}

function isCreditFinancingValid(credit) {
    return credit?.totalDueAmount > 0;
}

function isCreditFinancingEnabled(quotation) {
    return quotation.financingType === financingTypes.CREDIT && quotation.totalNetPriceToPay > 0;
}

function isCommercialDiscountValid({ commercialDiscount, commercialDiscountInValue }) {
    return commercialDiscount > 0 || commercialDiscountInValue > 0;
}

function calculateCommercialDiscountForZeroTotalNetPrice(quotation) {
    const discountRatio = 1 - quotation.energySavingCertificatePremium / quotation.totalPriceToPay;

    return discountRatio * 100;
}

function isZeroEuroButtonEnabled(quotationOrInvoice) {
    return (
        isRegulationSystemInstallation(quotationOrInvoice) &&
        quotationOrInvoice.energySavingCertificatePremium > 0 &&
        (quotationOrInvoice.totalNetPriceToPay > 1 || quotationOrInvoice.totalNetPriceToPay > 1)
    );
}

function isRegulationSystemInstallation({ operations = [] }) {
    return operations.some(({ operationSheetName }) => operationSheetName === operationSheetNames.BAR_TH_173V_A56_1_CDP);
}

function generateQuotationsExportFileName() {
    return LABEL_EXPORT_QUOTATIONS_PREFIX.concat(dateUtils.formatDate({ format: dateFormats.FILE_NAME }));
}

export default {
    formatQuotationsForTable,
    filterDraftQuotations,
    filterSentQuotations,
    getStatusLabel,
    getActionButtonLabel,
    changeEditionPathNameToPreviewPathname,
    formatCustomerName,
    getCustomerNumber,
    getWarrantyAreaLabel,
    isSameCustomer,
    getRenovationAddressesOptions,
    isSameRenovationAddress,
    isSameBuildingAgeValue,
    getSelectContractPlaceholder,
    getSelectContractOptions,
    isSameContract,
    isQuotationValid,
    getTotalPriceWithoutTax,
    getVatRates,
    getOperationsNames,
    formatOperationSheetName,
    isCustomerPM,
    isCustomerPP,
    hasAFileDownloadUrl,
    isCommercialDiscountPercentageDisabled,
    canShowCustomerEnergySavingCertificatePremium,
    canShowTotalVolume,
    getOperationsTotalVolume,
    canShowEnergySavingCertificatePremium,
    getCustomerEnergySavingCertificatePremiumLabel,
    getFormattedQuotationTotalVolume,
    isQuotationSendable,
    getQuotationSendButtonMessage,
    isQuotationPrintable,
    getQuotationPrintButtonMessage,
    canShowBackToEditionNotification,
    getQuotationInvoiceButtonMessage,
    getQuotationDeleteButtonMessage,
    isQuotationInvoiceable,
    isQuotationDeletable,
    isQuotationSent,
    filterSignedQuotations,
    formatQuotationIssueDate,
    getSelectOperationTechnicianMessage,
    doesQuotationHaveAtLeatOneCompliantOperation,
    canShowTotalPreTaxWithCommercialDiscountField,
    getTotalPreTaxWithCommercialDiscount,
    canShowCustomerEnergySavingCertificatePremiumForOperation,
    handleOpenQuotation,
    canShowTableMenuActions,
    canShowCustomerColumn,
    filterQuotationsWithVatCertificateAndFormatForTable,
    getOperationSheetNames,
    formatSelectContractOption,
    getAddNewOperationToolTipMessage,
    isAddOperationButtonDisabled,
    extractSolarFactorCalculationNote,
    isSelectContractEnabled,
    isQuotationExpired,
    isQuotationRefusable,
    canDisplayMixteMandatCheckbox,
    isSameCustomerPMAddress,
    formatCustomerPPName,
    canShowJoinProductCheckBox,
    extractMainProduct,
    isQuotationInvoiceableForAnAdvancePayment,
    getQuotationAdvancePaymentInvoiceButtonMessage,
    extractNonEnergyRenovationOperationMainProduct,
    isNonEnergyRenovationDocument,
    isPremiumApplicableForQuotation,
    doesTheEntityHasARenovationGlobalOrARenovationPremium,
    isARenovationAddressWithoutOccupant,
    isOnlyEnergyRenovationWorkOrServiceDocument,
    isEnergyRenovationWorkDocument,
    isEnergyRenovationServiceDocument,
    formatQuotationPrimesAmount,
    canCreateAQuotation,
    formatCreditFinancing,
    isCreditFinancingValid,
    isCreditFinancingEnabled,
    isCommercialDiscountValid,
    calculateCommercialDiscountForZeroTotalNetPrice,
    isZeroEuroButtonEnabled,
    isRegulationSystemInstallation,
    isWasteManagementValid,
    generateQuotationsExportFileName,
};
